import { styled } from '@mui/material'

const CardList = styled('section')(({ theme }) => ({
    overflowY: 'auto',
    padding: '8px',
    backgroundColor: theme.palette.background.default,

    '& > *': {
        '& ~ *': {
            marginTop: '8px',
        },
    },
}))

export default CardList
