import { useState } from 'react'
import useOutside from '../hooks/useOutside'
import CogIcon from '../icons/CogIcon'
import FlagIcon from '../icons/FlagIcon'
import ReportIssueModal from './ReportIssueModal'
import SettingsMenu from './SettingsMenu'
import AccountSettings from './admin-settings/container/AccountSettings'
import Button from './common/Button'
import TransparentButton from './common/TransparentButton'
import InfoTooltip from './common/tooltips/InfoTooltip'
import { styled, useTheme } from '@mui/material'

const Container = styled('section')(({ theme }) => ({
    color: theme.palette.text.primary,
    height: '64px',
    margin: '16px 16px 0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        margin: 0,
        borderRadius: 0,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}))

const SettingsButton = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    padding: '2px',
    borderRadius: '50%',
})

const SidebarFooter = () => {
    const { palette } = useTheme()
    const [isReportingIssue, setIsReportingIssue] = useState(false)
    const [accountSettingsOpen, setAccountSettingsOpen] = useState(false)
    const [menuActive, setMenuActive] = useState(false)

    const ref = useOutside<HTMLDivElement>(() => setMenuActive(false))

    const handleOpenIssueReport = () => {
        setIsReportingIssue(true)
        setMenuActive(false)
    }

    return (
        <Container>
            <div ref={ref}>
                <InfoTooltip title='Settings'>
                    <SettingsButton type='button' onClick={() => setMenuActive(!menuActive)}>
                        <CogIcon color={palette.primary.contrastText} aria-label='menu toggle' />
                    </SettingsButton>
                </InfoTooltip>

                {menuActive && <SettingsMenu onAccountSettingsOpen={() => setAccountSettingsOpen(true)} />}
            </div>
            <InfoTooltip title='Report an issue with the current session or the platform'>
                <TransparentButton onClick={handleOpenIssueReport} aria-label='Report issue'>
                    <FlagIcon color={palette.text.primary} width={24} height={18} />
                    Report Issue
                </TransparentButton>
            </InfoTooltip>

            <ReportIssueModal show={isReportingIssue} onClose={() => setIsReportingIssue(false)} />
            <AccountSettings open={accountSettingsOpen} onClose={() => setAccountSettingsOpen(false)} />
        </Container>
    )
}

export default SidebarFooter
