import FilterIcon from '../../../icons/FilterIcon'
import HelpIcon from '../../../icons/HelpIcon'
import WaitIcon from '../../../icons/WaitIcon'
import ErrorCard from '../../common/ErrorCard'
import Loading from '../../common/Loading'
import { ContentSectionProps, FilterSectionProps, cellWithFilterSectionStyles, dynamicDocumentCellStyles, dynamicHeightTableCell } from '../utils/styles'
import { DocumentResult } from '../utils/types'
import MarkdownMessage from '../../common/markdown/MarkdownMessage'
import { Tooltip, Typography, styled as muiStyled, styled, useTheme } from '@mui/material'
import { COLLAPSING_TRANSITION_TIME, DOCUMENT_COLUMN_COLLAPSED_WIDTH, DOCUMENT_HEADER_PADDING } from '../utils/utils'
import useDebounce from '../../../hooks/useDebounce'
import ExpandedInfoTooltip from '../../common/tooltips/ExpandedInfoTooltip'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

const CollapsibleCell = muiStyled('div')<{ collapsed: boolean; width: number }>(({ collapsed, width }) => ({
    ...dynamicDocumentCellStyles(collapsed, DOCUMENT_COLUMN_COLLAPSED_WIDTH + DOCUMENT_HEADER_PADDING, width + DOCUMENT_HEADER_PADDING),
    height: '100%',
}))

const Container = styled('td')(() => ({
    alignContent: 'start',
    padding: 0, // Background color is set on children so remove gaps
    ...dynamicHeightTableCell,
}))

const InnerContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<ContentSectionProps & FilterSectionProps>(
    ({ theme, $fullSize, $disabled }) => ({
        ...cellWithFilterSectionStyles.contentSectionStyles({ theme, $fullSize }),
        ...($disabled && {
            backgroundColor: theme.palette.action.disabledBackground,
        }),
    })
)

const FilterContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<FilterSectionProps>(({ theme, $disabled }) => ({
    ...cellWithFilterSectionStyles.filterSectionStyles({ theme, $disabled }),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const Markdown = styled(MarkdownMessage, { shouldForwardProp: forwardPropsWithTransient })<{ $outdated: boolean }>(({ $outdated }) => ({
    ...($outdated && {
        '& > *': {
            textDecoration: 'line-through',
        },
    }),
}))

const IconContainer = styled('span')(() => ({
    float: 'right',
}))

const StyledLoading = styled(Loading)(({ theme }) => ({
    width: '16px',
    height: '16px',
    borderTopColor: theme.palette.action.disabled,
}))

const CollapsedCell = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
}))

interface DocumentCellProps {
    documentResult: DocumentResult
    running: boolean
    digestMatch: boolean
    filtered: boolean | null
    collapsed: boolean
    width: number
}

const getDisplayText = (value: string) => {
    switch (value) {
        case 'true':
            return 'Yes'
        case 'false':
            return 'No'
        default:
            return value
    }
}

const DocumentCell = ({ documentResult: { state, value }, running, digestMatch, filtered, collapsed, width }: DocumentCellProps) => {
    const { palette } = useTheme()
    const debouncedShowTooltip = useDebounce(collapsed, COLLAPSING_TRANSITION_TIME)

    const getIcon = () => {
        switch (state) {
            case 'pending':
                return running ? <WaitIcon data-testid='wait icon' color={palette.text.disabled} /> : null
            case 'running':
                return running ? <StyledLoading /> : null
            case 'done':
                return digestMatch ? null : (
                    <ExpandedInfoTooltip heading='Content Outdated' description='Content in this cell is outdated and will change in the next run.'>
                        <HelpIcon data-testid='help icon' color={palette.text.disabled} width={18} height={18} />
                    </ExpandedInfoTooltip>
                )
            case 'skipped':
                return (
                    <ExpandedInfoTooltip
                        heading='Filtered Response'
                        description='A cell above did not match the filter, so this and subsequent cells have been skipped. Change the filter and run again if you need these results.'
                    >
                        <FilterIcon data-testid='filtered icon' filtered color={palette.text.disabled} />
                    </ExpandedInfoTooltip>
                )
            case 'generation_error':
                return <ErrorCard error={value ?? 'An error occurred whilst generating this response'} />
        }
    }

    const getFilterSection = () => {
        let title = 'Filtered Response'
        let description = 'This cell did not match the filter. Subsequent cells have been skipped. Change the filter and run again if you need these results.'
        let filterIcon: JSX.Element | null = <FilterIcon filtered={!!filtered} color={palette.text.disabled} />

        switch (filtered) {
            case null:
                return null
            case true:
                // Default values for the true case
                break
            case false:
                if (value) {
                    title = 'Filter Passed'
                    description = 'This cell matched the filter, so subsequent cells are active'
                    filterIcon = null
                } else {
                    title = 'Filter Applied'
                    description = 'A filter will be applied to this cell'
                }
                break
        }

        return (
            <ExpandedInfoTooltip heading={title} description={description}>
                <FilterContainer data-testid='doc cell filter' $disabled={filtered}>
                    {filterIcon}
                </FilterContainer>
            </ExpandedInfoTooltip>
        )
    }

    const icon = getIcon()
    const filterSection = getFilterSection()

    return (
        <Tooltip
            title={
                collapsed && value && state !== 'generation_error' && debouncedShowTooltip ? (
                    <Markdown $outdated={!digestMatch} variant='body1'>
                        {getDisplayText(value)}
                    </Markdown>
                ) : undefined
            }
            placement='right'
            disableInteractive
        >
            <Container>
                <CollapsibleCell collapsed={collapsed} width={width}>
                    {collapsed ? (
                        <CollapsedCell>
                            <Typography sx={{ textAlign: 'center' }}>{value && value.length > 0 && '...'}</Typography> {filterSection}
                        </CollapsedCell>
                    ) : (
                        <>
                            <InnerContainer data-testid='doc cell content' $disabled={state === 'skipped'} $fullSize={filtered === null}>
                                {icon && <IconContainer>{icon}</IconContainer>}
                                {value && state !== 'generation_error' && (
                                    <Markdown $outdated={!digestMatch} variant='body1'>
                                        {getDisplayText(value)}
                                    </Markdown>
                                )}
                            </InnerContainer>
                            {filterSection}
                        </>
                    )}
                </CollapsibleCell>
            </Container>
        </Tooltip>
    )
}

export default DocumentCell
