import { styled } from '@mui/material'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

interface SizingProps {
    $size: 'big' | 'small'
}

const getSize = (props: SizingProps) => (props.$size === 'big' ? 16 : 8)
const getShadowSize = (props: SizingProps) => (props.$size === 'big' ? 24 : 12)

const MessageLoading = styled('div', {
    shouldForwardProp: forwardPropsWithTransient,
})<SizingProps>(({ $size, theme }) => ({
    width: `${getSize}px`,
    height: `${getSize}px`,
    borderRadius: '50%',
    display: 'block',
    margin: `${$size === 'big' && '15px auto'}`,
    position: 'relative',
    background: theme.palette.common.white,
    boxShadow: `-${getShadowSize}px 0 ${theme.palette.common.white}, ${getShadowSize}px 0 ${theme.palette.common.white}`,
    boxSizing: 'border-box',
    animation: `${$size === 'big' ? 'bigShadowPulse' : 'smallShadowPulse'} 2s linear infinite`,

    '@keyframes bigShadowPulse': {
        '33%': {
            background: theme.palette.common.white,
            boxShadow: `-24px 0 ${theme.palette.primary.main}, 24px 0 ${theme.palette.common.white}`,
        },
        '66%': {
            background: theme.palette.primary.main,
            boxShadow: `-24px 0 ${theme.palette.common.white}, 24px 0 ${theme.palette.common.white}`,
        },
        '100%': {
            background: theme.palette.common.white,
            boxShadow: `-24px 0 ${theme.palette.common.white}, 24px 0 ${theme.palette.primary.main}`,
        },
    },

    '@keyframes smallShadowPulse': {
        '33%': {
            background: theme.palette.common.white,
            boxShadow: `-12px 0 ${theme.palette.primary.main}, 12px 0 ${theme.palette.common.white}`,
        },
        '66%': {
            background: theme.palette.primary.main,
            boxShadow: `-12px 0 ${theme.palette.common.white}, 12px 0 ${theme.palette.common.white}`,
        },
        '100%': {
            background: theme.palette.common.white,
            boxShadow: `-12px 0 ${theme.palette.common.white}, 12px 0 ${theme.palette.primary.main}`,
        },
    },
}))

export default MessageLoading
