import Button from '../../common/Button'
import CardList from '../../common/CardList'
import FileCard from '../../common/FileCard'
import UploadArea from '../../common/UploadArea'
import { areaLayoutStyles } from '../../common/styles'
import { useFileContext } from '../../../context/FileContext'
import { useState } from 'react'
import { UUID } from '../../../types'
import { usePost } from '../../../hooks/usePost'
import { skimmerDocumentsEndpoint } from '../../../endpoints'
import Loading from '../../common/Loading'
import ChatHeader from '../../ChatHeader'
import { styled } from '@mui/material'
import { useSkimmerContext } from '../../../context/SkimmerContext'

const StyledHeader = styled(ChatHeader)(({ theme }) => ({
    ...areaLayoutStyles(theme).header,
}))

const Content = styled('div')(({ theme }) => ({
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px 64px',
    ...areaLayoutStyles(theme).content,
}))

const StyledUploadArea = styled(UploadArea)(() => ({
    maxWidth: '1000px',
    width: '100%',
    margin: '32px',
    padding: '32px 0',
}))

const StyledCardList = styled(CardList)(() => ({
    maxWidth: '800px',
    width: '80%',
    padding: '0 16px',
    backgroundColor: 'inherit',
}))

const Footer = styled('div')(({ theme }) => ({
    ...areaLayoutStyles(theme).footer,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

interface UploadProps {
    onChange: (callback: () => Promise<boolean | null>) => void
    hideHeader?: boolean
}

const Upload = ({ onChange, hideHeader = false }: UploadProps) => {
    const { files, upload, uploading } = useFileContext()
    const { currentChat } = useSkimmerContext()

    const [failedUploadFileIds, setFailedUploadFileIds] = useState<UUID[]>()

    const [updateSkimmer, updating] = usePost<void, boolean>(skimmerDocumentsEndpoint(currentChat.id), { method: 'PATCH' })

    const handleUpload = async () => {
        const { uploadedIds, rejectedIds } = await upload()

        if (rejectedIds.length) {
            setFailedUploadFileIds(rejectedIds)
        } else if (uploadedIds.length) {
            onChange(() => updateSkimmer())
        }
    }

    const loading = uploading || updating

    return (
        <>
            {!hideHeader && <StyledHeader />}
            <Content>
                <StyledUploadArea clickable alwaysVisible />
                <StyledCardList>
                    {files?.map(({ id, file }) => (
                        <FileCard
                            key={id}
                            id={id}
                            name={file.name}
                            size={file.size}
                            error={
                                failedUploadFileIds?.includes(id)
                                    ? 'Document failed to upload, please try again or remove this document to continue'
                                    : undefined
                            }
                        />
                    ))}
                </StyledCardList>
            </Content>
            <Footer>
                <Button type='button' aria-label='upload documents' onClick={handleUpload} disabled={loading}>
                    Upload Documents
                    {loading && <Loading bar />}
                </Button>
            </Footer>
        </>
    )
}

export default Upload
