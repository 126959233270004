import { styled } from '@mui/material'
import RadioOption from './RadioOption'
import { InputHTMLAttributes } from 'react'
import { capitalizeFirstLetter } from '../../utils/formatting'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

const StyledRadioOption = styled(RadioOption)({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& ~ *': {
        marginTop: '8px',
    },
})

const isLabelledOption = <TOption,>(value: TOption | LabelledOption<TOption>): value is LabelledOption<TOption> =>
    typeof value === 'object' && value !== null && 'label' in value

const isLabelledOptionArray = <TOption,>(values: readonly TOption[] | LabelledOption<TOption>[]): values is LabelledOption<TOption>[] =>
    values.length < 1 || isLabelledOption(values[0])

interface LabelledOption<TOption> {
    label: string
    value: TOption
}

interface VerticalRadioProps<TOption> extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    className?: string
    options: readonly TOption[] | LabelledOption<TOption>[]
    value: TOption
    onChange: (value: TOption) => void
}

const VerticalRadio = <TOption extends string>({ className, options, value: selectedValue, onChange, ...rest }: VerticalRadioProps<TOption>) => (
    <Container className={className}>
        {isLabelledOptionArray(options)
            ? options.map(({ label, value }) => (
                  <StyledRadioOption key={value} label={label} checked={value === selectedValue} onChange={() => onChange(value)} {...rest} />
              ))
            : options.map(o => (
                  <StyledRadioOption key={o} label={capitalizeFirstLetter(o)} checked={o === selectedValue} onChange={() => onChange(o)} {...rest} />
              ))}
    </Container>
)

export default VerticalRadio
