import styled from 'styled-components'
import { UserMessage, isDocumentUserMessage } from '../types'
import DocumentMessage from './DocumentMessage'
import Loading from './common/Loading'
import MarkdownMessage from './common/markdown/MarkdownMessage'

const StyledLoading = styled(Loading)`
    width: 24px;
    height: 24px;
    margin-left: 15px;
`

interface UserMessageContentProps {
    message: UserMessage
}

const UserMessageContent = ({ message }: UserMessageContentProps) => {
    if (isDocumentUserMessage(message)) {
        if (message.documents) {
            return <DocumentMessage message={message} />
        }
        return <StyledLoading />
    }

    return <MarkdownMessage>{message.text}</MarkdownMessage>
}

export default UserMessageContent
