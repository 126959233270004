import { UIEvent, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'
import { useChatContext } from '../context/ChatContext'
import { useMessageContext } from '../context/MessageContext'
import { getMessagesEndpoint } from '../endpoints'
import useLoadingDebounce from '../hooks/useLoadingDebounce'
import { usePagedGet } from '../hooks/usePagedGet'
import { Message } from '../types'
import { canMergeWithoutIdConflicts } from '../utils/objectUtils'
import MessageDisplay from './MessageDisplay'
import CenteredContainer from './common/CenteredContainer'
import Loading from './common/Loading'
import MessageLoading from './common/MessageLoading'
import { Typography } from '@mui/material'

const Container = styled('section')({
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0 24px 0 24px',
    marginRight: '12px',
})

const LoadingContainer = styled('div')({
    position: 'sticky',
    top: '0',
    right: '50%',
})

let initialised = false
let lockScrollToBottom = true
let historyScroll = false

const scrollToBottom = (element: HTMLDivElement) => {
    element.scrollTop = element.scrollHeight
}

const checkScrollLock = (element: HTMLDivElement | null) => {
    if (lockScrollToBottom && element) {
        scrollToBottom(element)
    }
}

const chatHistoryBatchSize = 25

interface ChatWindowProps {
    className?: string
}

const ChatWindow = ({ className }: ChatWindowProps) => {
    const { currentChat } = useChatContext()
    const { incomingMessage, incomingProgressMessage, incomingChunk, outgoingMessage } = useMessageContext()
    const containerRef = useRef<HTMLDivElement>(null)

    const progressUpdate = incomingProgressMessage || currentChat?.progressUpdate || null
    // Count of how many messages have been sent/received through the websocket since we first loaded the chat history
    const [liveMessageOffset, setLiveMessageOffset] = useState(0)
    const [messages, setMessages] = useState<Message[]>([])
    const [chatHistory, loading, getNext] = usePagedGet<Message>(currentChat ? getMessagesEndpoint(currentChat.id) : null, chatHistoryBatchSize)

    const debouncedChatLock = useLoadingDebounce(!!currentChat && currentChat.status === 'locked', 1000)

    // Reset on chat change
    useEffect(() => {
        initialised = false
        lockScrollToBottom = true
        setLiveMessageOffset(0)
        setMessages([])
    }, [currentChat?.id])

    // Add retrieved chat history to list
    useEffect(() => {
        // Changing chat can cause messages to update before chatHistory does, so ensure we don't add history for previous chat
        const isForCurrentChat = chatHistory.length > 0 && currentChat?.id === chatHistory[0].chatId

        // To keep the effect pure we only want to update state if we haven't got the new data already
        // This stops duplicate data being rendered if the effect runs multiple times, like in strict mode
        // As each page of data should be unique, instead of doing a deep comparison we can just check ids
        if (isForCurrentChat && canMergeWithoutIdConflicts(messages, chatHistory)) {
            setMessages(messages => [...chatHistory.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()), ...messages])
            historyScroll = true
        }
    }, [chatHistory, messages, currentChat?.id])

    // Add user message to list
    useEffect(() => {
        if (outgoingMessage && outgoingMessage?.chatId === currentChat?.id && !messages.some(m => m.id === outgoingMessage.id)) {
            setLiveMessageOffset(o => ++o)
            setMessages(messages => [...messages, outgoingMessage])
        }
        // We only care about handling the new message when it happens and if it's for the current chat
        // Therefore we can ignore the current chat changing and avoid checking if we've already handled the message on current chat change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outgoingMessage])

    useEffect(() => {
        if (incomingChunk && incomingChunk.chatId === currentChat?.id) {
            const messageToUpdate = messages.find(m => m.id === incomingChunk.messageId)
            if (messageToUpdate) {
                // Mutate state to avoid re-renders, when we receive the full message state will be updated properly
                messageToUpdate.text += incomingChunk.text
                checkScrollLock(containerRef.current)
            }
        }
        // We only care about handling new chunks as they come in
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomingChunk])

    // Add bot response to list, update user message with server data or add a user message sent from a different tab
    useEffect(() => {
        if (incomingMessage && incomingMessage?.chatId === currentChat?.id) {
            !incomingMessage.fromUser && setLiveMessageOffset(o => ++o)
            setMessages(messages => [...messages.filter(m => m.id !== incomingMessage.id), incomingMessage])
        }
        // We only care about handling the new message when it happens and if it's for the current chat
        // Therefore we can ignore the current chat changing and avoid checking if we've already handled the message on current chat change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomingMessage])

    // Scroll behaviour
    useEffect(() => {
        if (containerRef.current && messages.length > 0) {
            const latestMessage = messages[messages.length - 1]

            if (!initialised) {
                // Initial history load
                scrollToBottom(containerRef.current)
                initialised = true
                historyScroll = false
            } else if (historyScroll) {
                // More chat history loaded, scroll to the oldest message in the previous batch
                const messageElement: Element | undefined = containerRef.current.children[0]?.children[chatHistory.length] as Element | undefined // Accessing by index so could be undefined, make the type safer
                messageElement?.scrollIntoView()
                historyScroll = false
            } else if (latestMessage.fromUser) {
                // User sent message
                // Ignore BE updating properties of the user message
                if (incomingMessage?.id !== latestMessage.id) {
                    scrollToBottom(containerRef.current)
                }
            } else {
                // Bot message received
                checkScrollLock(containerRef.current)
            }
        }
        // We only want to fire this effect once the new messages have been rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages])

    useEffect(() => {
        checkScrollLock(containerRef.current)
    }, [debouncedChatLock])

    const handleScroll = ({ currentTarget: { scrollTop, scrollHeight, clientHeight } }: UIEvent<HTMLElement>) => {
        const isScrolledToBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1

        if (initialised && !loading && scrollTop === 0) {
            getNext(liveMessageOffset)
            setLiveMessageOffset(0)
        } else if (lockScrollToBottom && !isScrolledToBottom) {
            lockScrollToBottom = false
        } else if (!lockScrollToBottom && isScrolledToBottom) {
            lockScrollToBottom = true
        }
    }

    if (!currentChat) {
        return (
            <CenteredContainer>
                <Typography variant='h1'>Create a chat to get started!</Typography>
            </CenteredContainer>
        )
    }

    return (
        <Container className={className} ref={containerRef} onScroll={handleScroll}>
            {loading && !initialised ? (
                <Loading fullSize primaryColor />
            ) : (
                <>
                    {loading && (
                        <LoadingContainer>
                            <MessageLoading $size='big' role='progressbar' aria-label='chat history loading' />
                        </LoadingContainer>
                    )}
                    <MessageDisplay messages={messages} loading={debouncedChatLock} progress={progressUpdate} />
                </>
            )}
        </Container>
    )
}

export default ChatWindow
