import { MouseEvent, PropsWithChildren, ReactNode, useState } from 'react'
import BotIcon from '../icons/BotIcon'
import { Bot, ToolType } from '../types'
import { stringToColour } from '../utils/colours'
import Editable from './common/Editable'
import TransparentButton from './common/TransparentButton'
import { overflowText } from './common/styles'
import InfoIcon from '../icons/InfoIcon'
import { styled, Typography } from '@mui/material'
import BotOverviewInfo from './BotOverviewInfo'
import FloatingCard from './common/tooltips/FloatingCard'
import InfoTooltip from './common/tooltips/InfoTooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import ToolMenu from './ToolMenu'
interface SizingProps {
    size: 'big' | 'small'
}

interface BotIconProps {
    botType: ToolType
    botName: string
}

const Container = styled('div')<SizingProps>(({ size }) => ({
    display: 'grid',
    gridTemplateColumns: size === 'big' ? '70px 1fr auto' : '64px 1fr auto',
    gridTemplateRows: '56% 44%',
    gridRowGap: '4px',
}))

const StyledBotIcon = styled('div')<BotIconProps>(({ theme, botName }) => ({
    gridArea: '1 / 1 / 3 / 2',
    justifySelf: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: stringToColour(botName, theme.palette.mode === 'dark'),
}))

const InnerContainer = styled('div')({
    gridArea: '1 / 2 / 3 / 3',
    alignSelf: 'center',
    overflow: 'hidden',
})

const headingStyles = {
    gridArea: '1 / 2 / 2 / 3',
    margin: 0,
    alignSelf: 'end',
    ...overflowText,
}

const Name = styled(Typography)({
    ...headingStyles,
    position: 'relative',
})

const SmallName = styled(Typography)({
    ...headingStyles,
})

const BigDescription = styled(Typography)({
    marginTop: '4px',
})

const CompactDescription = styled(Typography)({
    margin: 0,
    gridArea: '2 / 2 / 3 / 3',
    ...overflowText,
})

const ChildContainer = styled('div')({
    gridArea: '1 / 3 / 3 / 4',
    justifySelf: 'end',
    alignSelf: 'center',
    margin: '0 16px',
    display: 'flex',
})

const MenuButton = styled(TransparentButton)({
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const StyledEditable = styled(Editable)({
    ...headingStyles,
})

const StyledInfoIcon = styled(InfoIcon)({
    marginLeft: '8px',
    verticalAlign: 'top',
})

interface BaseCardProps extends SizingProps, PropsWithChildren {
    className?: string
    headingComponent: ReactNode
    subheading: string
    onClick?: () => void
    bot: Bot
    compact?: boolean
    showMenu?: boolean
}

const BaseCard = ({
    children,
    className,
    headingComponent: HeadingComponent,
    subheading,
    size,
    onClick,
    bot,
    compact = true,
    showMenu = false,
}: BaseCardProps) => {
    const [menuEl, setMenuEl] = useState<HTMLElement | null>(null)

    const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setMenuEl(event.currentTarget)
    }

    return (
        <Container className={className} size={size} onClick={onClick}>
            <StyledBotIcon botName={bot.name} botType={bot.toolType}>
                <BotIcon width={22} toolType={bot.toolType} color='#fff' />
            </StyledBotIcon>
            {compact ? (
                <>
                    {HeadingComponent}
                    <CompactDescription variant='h5'>
                        {subheading}
                        {size === 'big' && bot.aiEngines?.length > 0 && (
                            <FloatingCard title={<BotOverviewInfo aiEngines={bot.aiEngines} />} placement='right'>
                                <span>
                                    <StyledInfoIcon />
                                </span>
                            </FloatingCard>
                        )}
                    </CompactDescription>
                </>
            ) : (
                <InnerContainer>
                    {HeadingComponent}
                    {size === 'big' ? <BigDescription variant='h4'>{subheading}</BigDescription> : <Typography variant='h5'>{subheading}</Typography>}
                </InnerContainer>
            )}
            <ChildContainer>
                {children}
                {showMenu && (
                    <InfoTooltip title='More'>
                        <MenuButton type='button' onClick={handleMenuClick} aria-label='open tool menu'>
                            <MoreVertRoundedIcon sx={{ fontSize: 24 }} />
                        </MenuButton>
                    </InfoTooltip>
                )}
            </ChildContainer>
            <ToolMenu bot={bot} anchorEl={menuEl} onClose={() => setMenuEl(null)} />
        </Container>
    )
}

interface CardProps extends Omit<BaseCardProps, 'headingComponent'> {
    heading: string
}

export const Card = ({ heading, ...rest }: CardProps) => (
    <BaseCard
        headingComponent={
            rest.size === 'big' ? (
                <Name variant='h1' title={heading}>
                    {heading}
                </Name>
            ) : (
                <SmallName variant='h3' title={heading}>
                    {heading}
                </SmallName>
            )
        }
        {...rest}
    />
)

interface EditableCardProps extends CardProps {
    onNameSubmit: (name: string) => void
}

export const EditableCard = ({ heading, onNameSubmit, ...rest }: EditableCardProps) => (
    <BaseCard
        headingComponent={<StyledEditable label={heading} onLabelSubmit={onNameSubmit} typographyVariant={rest.size === 'big' ? 'h1' : 'h3'} role='heading' />}
        {...rest}
    />
)
