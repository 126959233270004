import { styled } from '@mui/material'
import Button from '../common/Button'

const BotConfigButton = styled(Button)({
    textAlign: 'center',
    marginLeft: '10px',
    alignSelf: 'end',
})

export default BotConfigButton
