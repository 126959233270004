import { PropsWithChildren, createContext, useContext } from 'react'
import { useGet } from '../hooks/useGet'
import { currentUserEndpoint } from '../endpoints'
import { Dollar, UUID, User, UserRole, doNothing } from '../types'

const SPRINGBOK_TENANT_ID = '74e9c9aa-1dd1-4a5e-856b-91524c73346c'

interface UserState {
    loading: boolean
    isMaintainer: boolean
    isAccountManager: boolean
    isSpringbokUser: boolean
    weeklyQuota: Dollar
    hasRemainingQuota: boolean
    isActive: boolean
    userEmail: string | null
    refreshUser: () => void
    isCurrentUser: (userId: UUID) => boolean
}

export const defaultState: UserState = {
    loading: true,
    isMaintainer: false,
    isAccountManager: false,
    isSpringbokUser: false,
    weeklyQuota: 0,
    hasRemainingQuota: true,
    isActive: true,
    userEmail: null,
    refreshUser: doNothing,
    isCurrentUser: () => false,
}

export const UserContext = createContext<UserState>(defaultState)

const WithUserContext = ({ children }: PropsWithChildren) => {
    const [user, loading, refresh] = useGet<User>(currentUserEndpoint)

    const isCurrentUser = (userId: UUID) => {
        return user?.id === userId
    }

    return (
        <UserContext.Provider
            value={{
                loading,
                isMaintainer: user ? user.role >= UserRole.Maintainer : defaultState.isMaintainer,
                isAccountManager: user ? user.role >= UserRole.AccountManager : defaultState.isAccountManager,
                isSpringbokUser: user ? user.tenantId === SPRINGBOK_TENANT_ID : defaultState.isSpringbokUser,
                weeklyQuota: user ? user.quota : defaultState.weeklyQuota,
                hasRemainingQuota: user ? user.costThisWeek < user.quota : defaultState.hasRemainingQuota,
                isActive: user?.isActive ?? defaultState.isActive,
                userEmail: user?.email ?? null,
                refreshUser: refresh,
                isCurrentUser: isCurrentUser,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

const useUserContext = () => useContext(UserContext)

export { WithUserContext, useUserContext }
