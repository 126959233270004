import UnstyledButton from './UnstyledButton'
import CrossIcon from '../../icons/CrossIcon'
import Loading from './Loading'
import { highlightOnHover } from './styles'
import { FC, SVGProps } from 'react'
import InfoTooltip from './tooltips/InfoTooltip'
import { styled } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '40px',
    border: `1px dashed ${theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.secondary.main}`,
    borderRadius: '8px',
    fontWeight: 'bold',
    width: '240px',
}))

const Label = styled(UnstyledButton)(({ theme, onClick }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 8px',
    height: '100%',
    width: '100%',
    borderRadius: '8px',

    ...(onClick && highlightOnHover(theme.palette.primary.main)),
    '&:hover': {
        cursor: onClick ? 'pointer' : 'default',
    },
}))

const StyledLoading = styled(Loading)({
    margin: '8px 0',
    width: '24px',
    height: '24px',
})

const IconButton = styled(UnstyledButton)(({ theme }) => ({
    height: '100%',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 8px 8px 0',
    backgroundColor: theme.palette.secondary.main,
    ...highlightOnHover(theme.palette.secondary.main),
}))

interface ChipProps {
    className?: string
    label: string
    title?: string
    loading?: boolean
    onClick?: () => void
    iconTitle?: string
    onIconClick?: () => void
    Icon?: FC<SVGProps<SVGSVGElement>>
}

const Chip = ({ className, label, title, loading, onClick, iconTitle, onIconClick, Icon = CrossIcon }: ChipProps) => {
    const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onIconClick && onIconClick()
    }

    return (
        <Container className={className} data-testid={`document-chip-${label}`}>
            <InfoTooltip title={title} sx={{ height: '100%', flex: 1, overflow: 'hidden' }}>
                <Label onClick={onClick} aria-label={onClick ? title : "this button currently doesn't do anything"}>
                    {label}
                </Label>
            </InfoTooltip>

            {loading && <StyledLoading />}
            {onIconClick && !loading && (
                <InfoTooltip title={iconTitle} sx={{ height: '100%' }}>
                    <IconButton onClick={e => handleIconClick(e)} aria-label='chip icon button'>
                        <Icon width={16} height={16} />
                    </IconButton>
                </InfoTooltip>
            )}
        </Container>
    )
}

export default Chip
