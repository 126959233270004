import { Menu, MenuList, MenuItem, ListItemText, ListItemIcon, useTheme } from '@mui/material'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import ConfigIcon from '../icons/ConfigIcon'
import { useBotContext } from '../context/BotContext'
import { Bot } from '../types'
import { useUserContext } from '../context/UserContext'
import { MouseEvent } from 'react'

interface ToolMenuProps {
    bot: Bot
    anchorEl: null | HTMLElement
    onClose: () => void
}

const ToolMenu = ({ bot, anchorEl, onClose }: ToolMenuProps) => {
    const { isMaintainer } = useUserContext()
    const { editBotConfig, viewBotDetails } = useBotContext()
    const { palette } = useTheme()

    const handleEditBotClick = (event: MouseEvent<HTMLLIElement>) => {
        event.stopPropagation()
        editBotConfig(bot)
        onClose()
    }

    const handleViewBotClick = (event: MouseEvent<HTMLLIElement>) => {
        event.stopPropagation()
        viewBotDetails(bot)
        onClose()
    }

    return (
        <Menu id='tool-menu' anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
            <MenuList data-testid='tool-menu-list' sx={{ width: '220px' }}>
                <MenuItem onClick={handleViewBotClick} aria-label='view tool details'>
                    <ListItemIcon>
                        <TipsAndUpdatesOutlinedIcon sx={{ color: palette.text.primary }} fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>View tool details</ListItemText>
                </MenuItem>
                {isMaintainer && (
                    <MenuItem onClick={handleEditBotClick} aria-label='configure tool'>
                        <ListItemIcon>
                            <ConfigIcon color={palette.text.primary} />
                        </ListItemIcon>
                        <ListItemText>Configure tool</ListItemText>
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}

export default ToolMenu
