import { CSSObject, styled, Theme } from '@mui/material'

export const inputStyles = (theme: Theme): CSSObject => {
    return {
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.text.primary,

        '&:disabled': {
            backgroundColor: theme.palette.divider,
            color: theme.palette.action.disabled,
            cursor: 'not-allowed',
        },

        '&:focus-visible': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`,
        },
    }
}

const Input = styled('input')(({ theme }) => ({
    ...inputStyles(theme),
}))
export default Input
