import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { CellUpdate, DocumentResult, isCellValueUpdate, PromptResult, ResponseType, SkimmerReport } from '../components/doc-skimmer/utils/types'
import { doNothingEventually, Document, doNothing, UUID, Dollar, DocumentUpdate, SkimmerChat } from '../types'
import { generateId, jsonClone } from '../utils/objectUtils'
import { useGet } from '../hooks/useGet'
import { useChatContext } from './ChatContext'
import { documentSkimmerEndpoint, runDocumentSkimmerEndpoint, skimmerDocumentsEndpoint, skimmerPromptsEndpoint } from '../endpoints'
import { calculateDigest, documentResultIsFiltered, generatePayload, generatePayloadIfDirty, placeholderPrompt } from '../components/doc-skimmer/utils/utils'
import { useDelete } from '../hooks/useDelete'
import { usePost } from '../hooks/usePost'
import { SkimmerPayload } from '../apiTypes'
import { toast } from 'react-toastify'
import { useMessageContext } from './MessageContext'
import { mockSkimmerChat } from '../tests/mockData'

interface SkimmerState {
    currentChat: SkimmerChat
    loading: false | 'loading' | 'saving' | 'running'
    report: SkimmerReport | null
    addPrompt: (prompt?: string) => void
    editPrompt: (id: UUID) => (prompt: string) => Promise<void>
    deletePrompt: (id: UUID) => void
    changeOrder: (id: UUID) => (orderChange: 'up' | 'down') => void
    changeResponseType: (id: UUID) => (responseType: ResponseType) => Promise<void>
    changeFilter: (id: UUID) => (filter: null | boolean | string) => void
    deleteDocument: (id: UUID) => Promise<void>
    updateCostCap: (costCap: Dollar) => void
    saveAndRefresh: (callback: () => Promise<boolean | null>) => Promise<void>
    triggerRun: () => void
}

const defaultState: SkimmerState = {
    currentChat: mockSkimmerChat, // The chat is a required prop for the context so this will never be exposed
    loading: 'loading',
    report: null,
    addPrompt: doNothing,
    editPrompt: () => doNothingEventually,
    deletePrompt: doNothing,
    changeOrder: () => doNothing,
    changeResponseType: () => doNothingEventually,
    changeFilter: () => doNothing,
    deleteDocument: doNothingEventually,
    updateCostCap: doNothing,
    saveAndRefresh: doNothingEventually,
    triggerRun: doNothingEventually,
}

let debounceTimerId: number | undefined
let debounceCallback: Function | null

const clearDebounce = () => {
    clearTimeout(debounceTimerId)
    debounceCallback = null
}

const generateEmptyResults = (documents: Document[]): DocumentResult[] =>
    documents.map(doc => ({ documentId: doc.id, state: 'pending', promptDigest: null, value: null }))

const updateStateForPendingCells = (localReport: PromptResult[]): PromptResult[] =>
    localReport.map(promptResult => ({
        ...promptResult,
        results: promptResult.results.map(docResult => (docResult.promptDigest === promptResult.digest ? docResult : { ...docResult, state: 'pending' })),
    }))

const applyDocumentUpdate = (localDocuments: Document[], { documentId, state }: DocumentUpdate) =>
    localDocuments.map(d =>
        d.id === documentId
            ? {
                  ...d,
                  state: state,
              }
            : d
    )

const applyCellUpdate = (localReport: PromptResult[], cellUpdate: CellUpdate) => {
    const { promptId, documentId, state } = cellUpdate

    if (isCellValueUpdate(cellUpdate)) {
        const { promptDigest, text } = cellUpdate
        return localReport.map(promptResult =>
            promptResult.id === promptId
                ? {
                      ...promptResult,
                      digest: promptDigest,
                      results: promptResult.results.map(docResult =>
                          docResult.documentId === documentId ? { ...docResult, promptDigest, state, value: text } : docResult
                      ),
                  }
                : promptResult
        )
    }

    return localReport.map(promptResult =>
        promptResult.id === promptId
            ? { ...promptResult, results: promptResult.results.map(docResult => (docResult.documentId === documentId ? { ...docResult, state } : docResult)) }
            : promptResult
    )
}

const SkimmerContext = createContext<SkimmerState>(defaultState)

interface SkimmerContextProps extends PropsWithChildren {
    currentChat: SkimmerChat
}

const WithSkimmerContextPage = ({ children, currentChat }: SkimmerContextProps) => {
    const { lockCurrentChat, unlockCurrentChat } = useChatContext()
    const { incomingDocumentUpdate } = useMessageContext()
    const { incomingCellUpdate } = useMessageContext()

    const [serverReport, syncLoading, refresh] = useGet<SkimmerReport>(documentSkimmerEndpoint(currentChat.id))
    const [deletePrompt, deletingPrompt] = useDelete(skimmerPromptsEndpoint(currentChat.id))
    const [deleteDocument, deletingDocument] = useDelete(skimmerDocumentsEndpoint(currentChat.id))
    const [savePrompts, saving] = usePost<SkimmerPayload, boolean>(skimmerPromptsEndpoint(currentChat.id), { method: 'PATCH' })
    const [triggerRun, startingRun] = usePost<SkimmerPayload, boolean>(runDocumentSkimmerEndpoint(currentChat.id), { method: 'PATCH' })
    const [createSkimmer, creating] = usePost<void, boolean>(documentSkimmerEndpoint(currentChat.id))

    const [localReport, setLocalReport] = useState<PromptResult[] | null>(null)
    const [localDocuments, setLocalDocuments] = useState<Document[] | null>(null)
    const [costCapOverride, setCostCapOverride] = useState<Dollar | null>(null)

    const [skimmerCreated, setSkimmerCreated] = useState(false)

    const [prevLoading, setPrevLoading] = useState(syncLoading)
    if (syncLoading !== prevLoading) {
        setPrevLoading(syncLoading)

        if (!syncLoading && serverReport) {
            setLocalReport(jsonClone(serverReport.results))
            setLocalDocuments(jsonClone(serverReport.documents))
            setCostCapOverride(serverReport.costCap)
        }
    }

    const createSkimmerAndRefresh = async () => {
        const success = await createSkimmer()
        success && refresh()
    }

    // Create the skimmer if it doesn't exist. This should only run once
    if (!skimmerCreated && !creating && !syncLoading && !serverReport) {
        createSkimmerAndRefresh()
        setSkimmerCreated(true)
    }

    const [prevDocumentUpdate, setPrevDocumentUpdate] = useState(incomingDocumentUpdate)
    if (incomingDocumentUpdate && localDocuments && incomingDocumentUpdate !== prevDocumentUpdate) {
        setPrevDocumentUpdate(incomingDocumentUpdate)
        setLocalDocuments(applyDocumentUpdate(localDocuments, incomingDocumentUpdate))
    }

    const [prevCellUpdate, setPrevCellUpdate] = useState(incomingCellUpdate)
    if (incomingCellUpdate && localReport && incomingCellUpdate !== prevCellUpdate) {
        setPrevCellUpdate(incomingCellUpdate)
        setLocalReport(applyCellUpdate(localReport, incomingCellUpdate))
    }

    useEffect(
        () => () => {
            clearTimeout(debounceTimerId)
            debounceCallback && debounceCallback()
        },
        []
    )

    const saveIfDirty = async (localData: PromptResult[] | null, costCap: Dollar | null) => {
        const payload = generatePayloadIfDirty(serverReport, localData, costCap)
        if (payload) {
            return savePrompts(payload)
        }
    }

    const debouncedSave = (localData: PromptResult[] | null = localReport, costCap: Dollar | null = costCapOverride) => {
        clearDebounce()

        debounceCallback = () => {
            debounceCallback = null
            saveIfDirty(localData, costCap)
        }
        debounceTimerId = setTimeout(debounceCallback, 10000)
    }

    /**
     * Save any local changes to the server and then perform the callback action.
     * If the callback is successful refresh data from the server
     *
     * @param callback - Async method to run after save, should return boolean success status.
     */
    const handleSaveAndRefresh = async (callback: () => Promise<boolean | null>) => {
        clearDebounce()

        const payload = generatePayloadIfDirty(serverReport, localReport, costCapOverride)
        if (payload) {
            const saveSuccess = await savePrompts(payload)
            if (!saveSuccess) {
                return
            }
        }

        const requestSuccess = await callback()
        requestSuccess && refresh()
    }

    const handleAddPrompt = (prompt = placeholderPrompt) => {
        if (serverReport && localReport) {
            const update = localReport.concat({
                id: generateId(),
                prompt,
                digest: '',
                responseType: 'text',
                filterValue: null,
                results: generateEmptyResults(serverReport.documents),
            })
            setLocalReport(update)
            debouncedSave(update)
        }
    }

    const handleEditPrompt = (id: UUID) => async (prompt: string) => {
        if (localReport) {
            const update = await Promise.all(
                localReport.map(async p => (p.id === id && p.prompt !== prompt ? { ...p, prompt, digest: await calculateDigest(prompt, p.responseType) } : p))
            )
            setLocalReport(update)
            debouncedSave(update)
        }
    }

    const handleDeletePrompt = async (id: UUID) => handleSaveAndRefresh(() => deletePrompt(id))

    const handleReOrderPrompt = (id: UUID) => (orderChange: 'up' | 'down') => {
        if (localReport) {
            const currentIndex = localReport.findIndex(p => p.id === id)
            if (currentIndex === -1) return

            const newIndex = orderChange === 'up' ? currentIndex - 1 : currentIndex + 1

            if (newIndex < 0 || newIndex >= localReport.length) return

            const updatedReport = [...localReport]
            // swap the two rows with eachother.
            ;[updatedReport[newIndex], updatedReport[currentIndex]] = [updatedReport[currentIndex], updatedReport[newIndex]]

            setLocalReport(updatedReport)
            debouncedSave(updatedReport)
        }
    }

    const handleChangeResponseType = (id: UUID) => async (responseType: ResponseType) => {
        if (localReport) {
            const update = await Promise.all(
                localReport.map(async p => (p.id === id ? { ...p, filterValue: null, responseType, digest: await calculateDigest(p.prompt, responseType) } : p))
            )
            setLocalReport(update)
            debouncedSave(update)
        }
    }

    const handleFilterChange = (id: UUID) => (filterValue: null | boolean | string) => {
        if (localReport) {
            const update = localReport.map(p => {
                if (p.id === id) {
                    if (filterValue === null) {
                        return { ...p, filterValue }
                    }
                    if (typeof filterValue === 'boolean' && p.responseType === 'boolean') {
                        return { ...p, filterValue }
                    }
                    if (typeof filterValue === 'string' && p.responseType === 'text') {
                        return { ...p, filterValue }
                    }
                }

                return p
            })
            setLocalReport(update)
            debouncedSave(update)
        }
    }

    const handleDeleteDocument = (documentId: UUID) => handleSaveAndRefresh(() => deleteDocument(documentId))

    const handleCostCapChange = (newCap: Dollar) => {
        setCostCapOverride(newCap)
        debouncedSave(undefined, newCap)
    }

    const handleTriggerRun = async () => {
        if (serverReport && localReport?.length && serverReport.documents.length) {
            clearDebounce()
            lockCurrentChat()
            const success = await triggerRun(generatePayload(localReport, costCapOverride ?? serverReport.costCap))

            if (success) {
                setLocalReport(updateStateForPendingCells(localReport))
            } else {
                unlockCurrentChat()
            }
        } else if (!localReport?.length) {
            toast.warn('Please add at least one prompt')
        } else if (!serverReport?.documents.length) {
            toast.warn('Please add at least one document')
        }
    }

    const indexMap = new Map()
    serverReport?.documents.forEach((doc, i) => indexMap.set(doc.id, i))

    const filteredDocumentIds: UUID[] = []
    const updatedResults: PromptResult[] = localReport
        ? localReport.map(promptResult => ({
              ...promptResult,
              results: promptResult.results
                  .sort((a, b) => indexMap.get(a.documentId) - indexMap.get(b.documentId))
                  .map(docResult => {
                      const filteredByPreviousPrompt = filteredDocumentIds.includes(docResult.documentId)

                      if (filteredByPreviousPrompt) {
                          return { ...docResult, state: 'skipped' }
                      }

                      if (documentResultIsFiltered(promptResult, docResult)) {
                          filteredDocumentIds.push(docResult.documentId)
                      }

                      if (docResult.state === 'skipped') {
                          return { ...docResult, state: 'pending' }
                      }

                      return docResult
                  }),
          }))
        : []

    return (
        <SkimmerContext.Provider
            value={{
                currentChat,
                loading: syncLoading || creating ? 'loading' : startingRun ? 'running' : saving || deletingPrompt || deletingDocument ? 'saving' : false,
                report: serverReport
                    ? {
                          chatId: serverReport.chatId,
                          documents: localDocuments || serverReport.documents,
                          results: updatedResults,
                          costCap: costCapOverride ?? serverReport.costCap,
                      }
                    : null,
                addPrompt: handleAddPrompt,
                editPrompt: handleEditPrompt,
                deletePrompt: handleDeletePrompt,
                changeOrder: handleReOrderPrompt,
                changeResponseType: handleChangeResponseType,
                changeFilter: handleFilterChange,
                deleteDocument: handleDeleteDocument,
                updateCostCap: handleCostCapChange,
                saveAndRefresh: handleSaveAndRefresh,
                triggerRun: handleTriggerRun,
            }}
        >
            {children}
        </SkimmerContext.Provider>
    )
}

const WithSkimmerContext = (props: SkimmerContextProps) => <WithSkimmerContextPage key={props.currentChat.id} {...props} />

const useSkimmerContext = () => useContext(SkimmerContext)

export { WithSkimmerContext, useSkimmerContext }
