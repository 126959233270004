import { styled } from '@mui/material'
import Button from './Button'
import { highlightOnHover } from './styles'

const TransparentButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderRadius: '8px',
    padding: '7px 10px',
    fontSize: '14px',
    fontWeight: 600,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    ...highlightOnHover(theme.palette.background.paper),
    '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.action.disabled,
    },
}))

export default TransparentButton
