import { styled, Typography, useTheme } from '@mui/material'
import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, useState } from 'react'
import AddIcon from '../../icons/AddIcon'
import CrossIcon from '../../icons/CrossIcon'
import { UUID } from '../../types'
import Breadcrumb from '../common/Breadcrumb'
import Button from '../common/Button'
import ErrorCard from '../common/ErrorCard'
import Input from '../common/Input'
import TextArea from '../common/TextArea'
import UnstyledButton from '../common/UnstyledButton'
import WithLabels from '../common/WithLabels'
import DeleteTooltip from '../common/tooltips/DeleteTooltip'
import { StyledDeleteButton } from './EditPrompt'
import { DeleteFilterButton } from './PromptLibraryWrapper'
import { maxNumberOfTags, maxTagCharacterLength } from './promptUtils'
import { PromptLibrary, PromptLibraryPayload, PromptTag } from './types'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { canDeleteLibrary } from './permissions'
import { noLibraryDeletePermission } from '../../utils/userMessages'

const StyledForm = styled('form')({
    margin: '0 auto',
    width: '60%',
    height: '80%',
})

const StyledTextArea = styled(TextArea)({
    minHeight: '100px',
    maxHeight: '150px',
    resize: 'vertical',
})

const TagInputContainer = styled(WithLabels)({
    position: 'relative',
    display: 'flex',
    alignItems: 'left',
})

const AddTagButton = styled(UnstyledButton)({
    position: 'absolute',
    right: '10px',
    top: '42px',
})

const TagContainer = styled('div')({
    display: 'flex',
    minHeight: '25px', // to hold an empty space for the tags. Otherwise it resizes.
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '5px',
})

const StyledChip = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '25px',
    padding: '2px 2px 2px 10px',
    '& button': {
        marginLeft: '2px',
    },
}))

const SubmissionRow = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
})

interface LibraryFormError {
    titleError: string | null
    descriptionError: string | null
    tagError: string | null
}

interface LibraryFormProps {
    loading: boolean
    library?: PromptLibrary
    onBack: () => void
    onSubmit: (payload: PromptLibraryPayload) => void
    onDelete?: (libraryId: UUID) => void
}

const LibraryForm = ({ loading, library, onBack, onSubmit, onDelete }: LibraryFormProps) => {
    const { palette } = useTheme()

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
    const [tagList, setTagList] = useState<PromptTag[]>(library ? library.tags : [])
    const [currentTag, setCurrentTag] = useState<string>('')
    const [title, setTitle] = useState<string>(library ? library.title : '')
    const [description, setDescription] = useState<string>(library ? library.description : '')
    const [formErrors, setFormErrors] = useState<LibraryFormError>({ titleError: null, descriptionError: null, tagError: null })

    const handleSubmitTag = (event: KeyboardEvent | MouseEvent) => {
        if (tagList.length >= maxNumberOfTags) {
            setFormErrors({ ...formErrors, tagError: `You can only add ${maxNumberOfTags} tags. Please remove some to add more.` })
            event.preventDefault()
        }

        if (event.type === 'click' || ('key' in event && event.key === 'Enter')) {
            event.preventDefault()

            const tagName = currentTag.trim()
            if (tagName) {
                if (tagList.find(t => t.name === tagName)) {
                    setFormErrors({ ...formErrors, tagError: 'Tag already exists' })
                } else {
                    setFormErrors({ ...formErrors, tagError: null })
                    setCurrentTag('')
                    setTagList(tagList.concat({ name: tagName, description: `A tag named: ${tagName}` }))
                }
            }
        }
    }

    const removeTag = (tag: PromptTag) => {
        setTagList(tagList.filter(item => item !== tag))
        setFormErrors({ ...formErrors, tagError: null })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (title === '') {
            setFormErrors({ tagError: null, descriptionError: null, titleError: 'Please give the library a name before submitting.' })
        } else if (description === '') {
            setFormErrors({ tagError: null, titleError: null, descriptionError: 'Please give the library a description before submitting.' })
        } else {
            // clear errors as the below api sometimes takes a second to finish loading
            setFormErrors({ tagError: null, titleError: null, descriptionError: null })
            onSubmit({
                title,
                description,
                tags: tagList,
            })
        }
    }

    return (
        <>
            <Breadcrumb onClick={onBack} label='Back to Library' />
            <StyledForm onSubmit={handleSubmit} noValidate>
                <WithLabels label='Name' required>
                    {formErrors.titleError && <ErrorCard error={formErrors.titleError} />}
                    <Input placeholder='Name' value={title} onChange={e => setTitle(e.target.value)} required />
                </WithLabels>
                <WithLabels label='Description' required>
                    {formErrors.descriptionError && <ErrorCard error={formErrors.descriptionError} />}
                    <StyledTextArea
                        placeholder='Description'
                        value={description}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
                        required
                    />
                </WithLabels>
                <TagInputContainer label='Tags'>
                    <Input
                        placeholder="Tag Name (press 'Enter' to add)"
                        value={currentTag}
                        onChange={e => setCurrentTag(e.target.value)}
                        onKeyDown={handleSubmitTag}
                        maxLength={maxTagCharacterLength}
                    />
                    {formErrors.tagError && <ErrorCard error={formErrors.tagError} />}
                    {tagList.length < maxNumberOfTags && (
                        <AddTagButton onClick={handleSubmitTag} title='Add Tag' aria-label='Add Tag'>
                            <AddIcon />
                        </AddTagButton>
                    )}
                </TagInputContainer>
                <TagContainer>
                    {tagList.map(tag => (
                        <StyledChip key={tag.name}>
                            <Typography variant='body2'>{tag.name}</Typography>
                            <DeleteFilterButton onClick={() => removeTag(tag)} title='Remove Tag' aria-label='Remove Tag'>
                                <CrossIcon color={palette.text.primary} strokeWidth='1px' />
                            </DeleteFilterButton>
                        </StyledChip>
                    ))}
                </TagContainer>
                <SubmissionRow>
                    <Button type='submit' disabled={loading} aria-label='Save Library'>
                        Save Library
                    </Button>
                    {library && onDelete && (
                        <div>
                            <DeleteTooltip
                                id={library.id}
                                targetName='prompt library'
                                description='This prompt library will be permanently deleted.'
                                onDelete={() => onDelete(library.id)}
                                open={deleteTooltipOpen}
                                handleClose={() => setDeleteTooltipOpen(false)}
                                placement='top'
                            >
                                <InfoTooltip title={canDeleteLibrary(library.userRole) ? undefined : noLibraryDeletePermission}>
                                    <StyledDeleteButton
                                        onClick={() => setDeleteTooltipOpen(true)}
                                        type='button'
                                        aria-label='delete library'
                                        disabled={!canDeleteLibrary(library.userRole)}
                                    >
                                        Delete
                                    </StyledDeleteButton>
                                </InfoTooltip>
                            </DeleteTooltip>
                        </div>
                    )}
                </SubmissionRow>
            </StyledForm>
        </>
    )
}

export default LibraryForm
