import { CircularProgress, styled, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { WithSkimmerContext, useSkimmerContext } from '../../../context/SkimmerContext'
import { skimmerExportEndpoint } from '../../../endpoints'
import { useGet } from '../../../hooks/useGet'
import useIsMobile from '../../../hooks/useIsMobile'
import ExportIcon from '../../../icons/ExportIcon'
import ChatHeader from '../../ChatHeader'
import WithValidation from '../../WithValidation'
import Button from '../../common/Button'
import CenteredContainer from '../../common/CenteredContainer'
import Loading from '../../common/Loading'
import MissingChat from '../../common/MissingChat'
import TransparentButton from '../../common/TransparentButton'
import UnavailableInMobile from '../../common/UnavailableInMobile'
import { areaLayoutStyles } from '../../common/styles'
import ProgressUpdate from '../components/ProgressUpdate'
import SkimmerTable from '../components/SkimmerTable'
import CostLimit from '../components/CostLimit'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'
import { SkimmerChat } from '../../../types'
import InfoTooltip from '../../common/tooltips/InfoTooltip'

const StyledHeader = styled(ChatHeader)(({ theme }) => ({
    ...areaLayoutStyles(theme).header,
}))

const Content = styled('div')(({ theme }) => ({
    ...areaLayoutStyles(theme).content,
    height: '75%',
    marginTop: '24px',
    padding: '0 16px 0 40px',
    overflow: 'auto',
}))

const InnerContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}))

const StyledFooter = styled('div')(({ theme }) => ({
    ...areaLayoutStyles(theme).footer,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto) 1fr repeat(3, auto)',
    columnGap: '32px',
    alignItems: 'center',
    margin: '0 25px',
}))

const StyledCostLimit = styled(CostLimit)({
    gridArea: '1 / 4 / 2 / 5',
})

const RunButton = styled(Button)({
    gridArea: '1 / 5 / 2 / 6',
})

const StyledProgressUpdate = styled(ProgressUpdate, { shouldForwardProp: forwardPropsWithTransient })<{ $show: boolean }>(({ $show }) => ({
    gridArea: '1 / 6 / 2 / 7',
    visibility: $show ? 'visible' : 'hidden',
}))

const WrappedSkimmerLayout = () => {
    const { palette } = useTheme()
    const isMobile = useIsMobile()
    const { currentChat, loading, report, saveAndRefresh, updateCostCap, triggerRun } = useSkimmerContext()

    const [exportToExcelUrl, loadingExportUrl, refreshExportUrl] = useGet<string>(skimmerExportEndpoint(currentChat.id), {
        requestOnRender: false,
    })

    useEffect(() => {
        exportToExcelUrl && window.open(exportToExcelUrl, '_blank')
    }, [exportToExcelUrl])

    if (isMobile) {
        return <UnavailableInMobile featureName='Tables' />
    }

    const handleExport = () => {
        // This wrapper is just so we can reuse the method from the context as is.
        // We want to save before triggering the export but not trigger a refresh.
        // It requires knowledge of how the context works which is a bit gross.
        const wrapper = async (): Promise<false> => {
            refreshExportUrl()
            return false
        }
        saveAndRefresh(wrapper)
    }

    const running = currentChat.status === 'locked' || loading === 'running'
    const processing = running || !!loading
    // BE does not send state updates for processing, therefore we presume until updated otherwise
    const documentProcessingCount = report?.documents?.filter(doc => doc.state !== 'extracted').length ?? 0
    const locked = processing || currentChat.status !== 'active'

    return (
        <>
            <StyledHeader>
                <InfoTooltip title='Print or save a copy of your chat'>
                    <TransparentButton type='button' aria-label='export to excel' disabled={processing} onClick={handleExport}>
                        {loadingExportUrl ? (
                            <CircularProgress size={24} />
                        ) : (
                            <ExportIcon color={processing ? palette.text.disabled : palette.text.primary} height={18} />
                        )}
                        Export Table
                    </TransparentButton>
                </InfoTooltip>
            </StyledHeader>
            <Content>
                {report ? (
                    <InnerContainer>
                        <SkimmerTable report={report} running={running} locked={locked} />
                    </InnerContainer>
                ) : loading ? (
                    <CenteredContainer>
                        <Loading fullSize primaryColor />
                    </CenteredContainer>
                ) : (
                    <MissingChat /> // Users shouldn't be able to get here
                )}
            </Content>
            <WithValidation>
                <StyledFooter>
                    {report && <StyledCostLimit costCap={report.costCap} onCostCapChange={updateCostCap} />}
                    <RunButton type='button' aria-label='run skimmer' disabled={locked} onClick={triggerRun}>
                        Run Prompts
                    </RunButton>
                    <StyledProgressUpdate
                        $show={locked || documentProcessingCount > 0}
                        running={running}
                        saving={loading === 'saving'}
                        processingCount={documentProcessingCount}
                    />
                </StyledFooter>
            </WithValidation>
        </>
    )
}

interface SkimmerLayoutProps {
    currentChat: SkimmerChat
}

const SkimmerLayout = ({ currentChat }: SkimmerLayoutProps) => (
    <WithSkimmerContext currentChat={currentChat}>
        <WrappedSkimmerLayout />
    </WithSkimmerContext>
)

export default SkimmerLayout
