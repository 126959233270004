import { usePost } from '../../hooks/usePost'
import { adminUsersEndpoint, promptPermissionsEndpoint, promptPublishingEndpoint, promptSharingEndpoint, promptUnsharingEndpoint } from '../../endpoints'
import { SharingRole, User, UUID } from '../../types'
import { useGet } from '../../hooks/useGet'
import { PermissionResponse } from '../../apiTypes'
import { useDelete } from '../../hooks/useDelete'
import { useUserContext } from '../../context/UserContext'
import { canShareLibrary } from './permissions'
import SharingPopover from '../common/SharingPopover'
import { usePromptContext } from '../../context/PromptContext'

interface PromptSharingProps {
    libraryId: UUID
    role: SharingRole
    open: boolean
    anchorEl: HTMLElement | null
    onClose: () => void
}

const PromptSharing = ({ libraryId, role, open, anchorEl, onClose }: PromptSharingProps) => {
    const { isMaintainer, isCurrentUser } = useUserContext()
    const { refreshLibraries } = usePromptContext()
    const canShare = canShareLibrary(isMaintainer, role)
    const [allUsers, usersLoading] = useGet<User[]>(isMaintainer ? adminUsersEndpoint : null)
    const [permissionsResponse, permissionsLoading, refreshPermissionsResponse] = useGet<PermissionResponse>(
        canShare ? promptPermissionsEndpoint(libraryId) : null
    )
    const [updateUser, loadingUpdateUser] = usePost<{ userId: UUID; role: SharingRole }, boolean>(promptSharingEndpoint(libraryId))
    const [removeUser, loadingRemoveUser] = useDelete(promptUnsharingEndpoint(libraryId))
    const [publish, loadingPublish] = usePost<null, boolean>(promptPublishingEndpoint(libraryId))
    const [unpublish, loadingUnpublish] = useDelete(promptPublishingEndpoint)

    if (!permissionsResponse || !allUsers) return null

    // Should be a string literal union type, but Dropdown only supports string currently
    const handleUpdateUser = async (userId: UUID, role: string) => {
        let request: Promise<boolean | null> = Promise.resolve(false)

        switch (role) {
            case 'Remove': {
                request = removeUser(userId)
                break
            }
            case 'Viewer':
            case 'Editor':
                request = updateUser({ userId, role: SharingRole[role] })
                break
        }

        const success = await request
        if (success) {
            refreshPermissionsResponse()
            isCurrentUser(userId) && refreshLibraries()
            return true
        } else {
            return false
        }
    }

    const handlePublishToggle = async () => {
        if (permissionsResponse) {
            const success = await (permissionsResponse.isPublished ? unpublish(libraryId) : publish(null))
            success && refreshPermissionsResponse()
        }
    }

    const loading = usersLoading || loadingUpdateUser || loadingRemoveUser || permissionsLoading || loadingPublish || loadingUnpublish

    return (
        <SharingPopover
            canShare={canShare}
            permissionsResponse={permissionsResponse}
            allUsers={allUsers}
            loading={loading}
            onUpdateUser={handleUpdateUser}
            onPublishToggle={handlePublishToggle}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        />
    )
}

export default PromptSharing
