import { Link, styled, useTheme } from '@mui/material'
import { useDocumentDownload } from '../../../hooks/useDocumentDownload'
import DocumentIcon from '../../../icons/DocumentIcon'
import { UUID } from '../../../types'
import Loading from '../../common/Loading'

const LinkWrapper = styled(Link)({
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    cursor: 'pointer',
})

const StyledLoading = styled(Loading)({
    height: '10px',
    width: '10px',
})

interface ReferenceLinkDownloadProps {
    documentName: string
    documentId: UUID
}

const ReferenceLinkDownload = ({ documentName, documentId }: ReferenceLinkDownloadProps) => {
    const { palette } = useTheme()

    const [, download, debouncedLoading] = useDocumentDownload(documentId)

    return (
        <LinkWrapper onClick={download} title={`download: ${documentName}`}>
            <DocumentIcon color={palette.text.secondary} />
            {documentName}
            {debouncedLoading ? <StyledLoading primaryColor /> : null}
        </LinkWrapper>
    )
}

export default ReferenceLinkDownload
