import { ChangeEvent, useState } from 'react'
import Input from '../common/Input'
import Dropdown from '../common/Dropdown'
import useDebounce from '../../hooks/useDebounce'
import CrossIcon from '../../icons/CrossIcon'
import MiniIconButton from '../common/MiniIconButton'
import { usePromptContext } from '../../context/PromptContext'
import CenteredContainer from '../common/CenteredContainer'
import {
    noLibraryEditPermission,
    noPromptEditPermission,
    noPromptLibrariesAvailable,
    noPromptsAvailable,
    noPromptsAvailableForLibrary,
    noPromptsAvailableForLibraryOrAllFiltered,
    noSelectedLibrary,
} from '../../utils/userMessages'
import PromptLibrary from './PromptLibrary'
import { sortPrompts } from './promptUtils'
import AddIcon from '../../icons/AddIcon'
import IconButton from '../common/IconButton'
import TransparentButton from '../common/TransparentButton'
import { Typography, useTheme, styled } from '@mui/material'
import { forwardPropsWithTransient } from '../../utils/muiUtils'
import PromptSharing from './PromptSharing'
import { canEditLibrary, canEditPrompt } from './permissions'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { ButtonTooltipWrapper } from './styles'
import EditIcon from '../../icons/EditIcon'
import Button from '../common/Button'
import ExportLibraryButton from './ExportLibraryButton'
import { useUserContext } from '../../context/UserContext'
import { UUID } from '../../types'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
})

const FlexibleHeightSection = styled('div')({
    flex: 1,
    overflowY: 'auto',
})

const BottomSection = styled('div')({
    display: 'flex',
    justifyContent: 'center',
})

const NoLibrariesContainer = styled(CenteredContainer)({
    flexDirection: 'column',
    '& button': {
        marginTop: '32px',
    },
})

const Title = styled(Typography)({
    margin: '0',
})

const LibraryHeaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

const TitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
})

const ResultsInfoRow = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0',
})

const HeaderRow = styled(ResultsInfoRow)(({ theme }) => ({
    margin: '0 0 10px 0',
    padding: '0 40px 8px 0',
    borderBottom: `2px solid ${theme.palette.divider}`,
}))

const LibraryButtonsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    alignItems: 'center',
})

const ShareButton = styled(Button)({
    padding: '2px 20px',
})

const FilterInfo = styled('div')(({ theme }) => ({
    position: 'relative',
    gap: '123px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
        gap: '130px',
    },

    [theme.breakpoints.up('lg')]: {
        gap: '136px',
    },
}))

const FiltersContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
})

const InnerFilterContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
})

const FilterWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
})

const SortLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    paddingRight: '10px',
    paddingTop: '14px',
}))

const SearchBar = styled(Input)(({ theme }) => ({
    flexBasis: '300px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    height: '21px',
    margin: '0px 10px 10px 0px',
}))

const FilterChipsContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '600px',
    maxHeight: '90px',
    overflow: 'auto',
})

const StyledChip = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    height: '32px',
    padding: '0px 10px',
    margin: '5px 0px 0px 10px',
}))

export const DeleteFilterButton = styled(MiniIconButton)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    marginLeft: '10px',
    height: '18px',
    width: '18px',
    '& svg': {
        height: '10px',
        width: '10px',
    },
}))

const StyledPromptLibrary = styled(PromptLibrary)(() => ({
    maxHeight: 100,
}))

interface ClearAllFiltersButtonProps {
    $isVisible: boolean
}

const ClearAllFiltersButton = styled(TransparentButton, {
    shouldForwardProp: forwardPropsWithTransient,
})<ClearAllFiltersButtonProps>(({ $isVisible }) => ({
    opacity: $isVisible ? 1 : 0,
    transition: 'background-color 0.2s, opacity 0.3s',
    border: 'none',
    padding: '2px 8px',
    cursor: $isVisible ? 'pointer' : 'default',
}))

const CreatePromptButton = styled(IconButton)({
    marginTop: '10px',
    marginRight: '10px',
})

const options = ['Alphabetical', 'Most Votes', 'Newest First', 'Oldest First'] as const

export type SortOptions = (typeof options)[number]

const isSortOption = (option: unknown): option is SortOptions => typeof option === 'string' && options.includes(option as SortOptions)

const PromptLibraryWrapper = () => {
    const { palette } = useTheme()
    const { isMaintainer } = useUserContext()
    const { prompts, promptLibraries, selectedLibrary, onEditLibrary, onLibraryChange, onSelectPrompt } = usePromptContext()

    const [sharingAnchor, setSharingAnchor] = useState<HTMLElement | null>(null)
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [selectedSort, setSelectedSort] = useState<SortOptions>('Alphabetical')
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 800)

    const [prevLibraryId, setPrevLibraryId] = useState<UUID | undefined>(selectedLibrary?.id)
    if (selectedLibrary?.id !== prevLibraryId) {
        setPrevLibraryId(selectedLibrary?.id)
        setSelectedTags([])
    }

    const removeTag = (tag: string) => {
        const newTags = selectedTags.filter(item => item !== tag)
        setSelectedTags(newTags)
    }

    const clearAllFilters = () => {
        setSelectedTags([])
        setSearchTerm('')
        setSelectedSort('Alphabetical')
    }

    if (!promptLibraries || promptLibraries.length === 0) {
        return (
            <NoLibrariesContainer>
                <Typography variant='h6'>{noPromptLibrariesAvailable}</Typography>
                <IconButton label='Create Library' onClick={() => onLibraryChange('new')}>
                    <AddIcon color={palette.primary.contrastText} />
                </IconButton>
            </NoLibrariesContainer>
        )
    }

    if (!prompts) {
        return (
            <CenteredContainer>
                <Typography variant='h6'>{noPromptsAvailable}</Typography>
            </CenteredContainer>
        )
    }

    if (!selectedLibrary) {
        return (
            <CenteredContainer>
                <Typography variant='h6'>{noSelectedLibrary}</Typography>
            </CenteredContainer>
        )
    }

    const selectedLibraryPrompts = prompts.filter(prompt => selectedLibrary?.promptIds.includes(prompt.id))
    const filteredPrompts = selectedLibraryPrompts.filter(
        prompt =>
            (selectedTags.length === 0 || selectedTags.some(tag => prompt.tagList.map(t => t.name).includes(tag))) &&
            (prompt.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
                prompt.description.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    )

    const sortedFilteredPrompts = sortPrompts(filteredPrompts, selectedSort)
    const sortingFilters = new Set(options)
    const allTags = new Set(selectedLibraryPrompts.flatMap(prompt => prompt.tagList.map(tag => tag.name)))
    const clearAllVisible = selectedTags.length > 0 || searchTerm !== '' || selectedSort !== 'Alphabetical'

    return (
        <>
            <HeaderRow>
                <LibraryHeaderContainer>
                    <TitleContainer>
                        <Title variant='h2'>{selectedLibrary.title}</Title>
                        <ButtonTooltipWrapper title={canEditLibrary(selectedLibrary.userRole) ? 'Edit Library' : noLibraryEditPermission}>
                            <MiniIconButton
                                onClick={() => onEditLibrary(true)}
                                disabled={!canEditPrompt(selectedLibrary.userRole)}
                                type='button'
                                aria-label='Edit Library Button'
                            >
                                <EditIcon color={canEditLibrary(selectedLibrary.userRole) ? palette.text.primary : palette.text.disabled} />
                            </MiniIconButton>
                        </ButtonTooltipWrapper>
                    </TitleContainer>
                    <Typography>{selectedLibrary.description}</Typography>
                </LibraryHeaderContainer>
                {isMaintainer && (
                    <LibraryButtonsContainer>
                        {selectedLibrary.userRole === 2 && <ExportLibraryButton libraryId={selectedLibrary.id} />}
                        {selectedLibrary.userRole > 0 && (
                            <ShareButton
                                onClick={(event: { currentTarget: HTMLButtonElement }) => setSharingAnchor(event.currentTarget)}
                                type='button'
                                aria-label='Share Library'
                            >
                                <Typography variant='h6'>Share</Typography>
                            </ShareButton>
                        )}
                    </LibraryButtonsContainer>
                )}
            </HeaderRow>

            <FiltersContainer>
                <InnerFilterContainer>
                    <SearchBar
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                    ></SearchBar>
                    <FilterWrapper>
                        <Dropdown
                            options={allTags}
                            selected={selectedTags}
                            onSelectionChange={setSelectedTags}
                            multiple
                            placeholder='Filter'
                            aria-label='filter'
                        />
                        <FilterChipsContainer>
                            {selectedTags.map(tag => (
                                <StyledChip key={tag} aria-label={tag}>
                                    <Typography variant='h6'>{tag}</Typography>
                                    <DeleteFilterButton name={tag} onClick={() => removeTag(tag)}>
                                        <CrossIcon color={palette.primary.contrastText} strokeWidth='2px' />
                                    </DeleteFilterButton>
                                </StyledChip>
                            ))}
                        </FilterChipsContainer>
                    </FilterWrapper>
                </InnerFilterContainer>
                <InnerFilterContainer>
                    <SortLabel>Sort:</SortLabel>
                    <Dropdown
                        options={sortingFilters}
                        selected={selectedSort}
                        onSelectionChange={selection => {
                            isSortOption(selection) && setSelectedSort(selection)
                        }}
                        placeholder='Alphabetical'
                    />
                </InnerFilterContainer>
            </FiltersContainer>
            <ResultsInfoRow>
                <Typography>
                    {filteredPrompts.length} match{filteredPrompts.length !== 1 && 'es'}
                </Typography>
                <FilterInfo>
                    <ClearAllFiltersButton
                        onClick={clearAllFilters}
                        $isVisible={clearAllVisible}
                        aria-label={clearAllVisible ? 'Clear All Filters' : 'No Filters to Clear'}
                    >
                        <Typography>Reset Filters</Typography>
                    </ClearAllFiltersButton>
                </FilterInfo>
            </ResultsInfoRow>

            <Container>
                <FlexibleHeightSection>
                    {sortedFilteredPrompts.length === 0 ? (
                        <CenteredContainer>
                            <Typography variant='h6'>{clearAllVisible ? noPromptsAvailableForLibraryOrAllFiltered : noPromptsAvailableForLibrary}</Typography>
                        </CenteredContainer>
                    ) : (
                        <StyledPromptLibrary prompts={sortedFilteredPrompts} />
                    )}
                </FlexibleHeightSection>

                <BottomSection>
                    <InfoTooltip title={canEditPrompt(selectedLibrary.userRole) ? undefined : noPromptEditPermission}>
                        <CreatePromptButton
                            label='Create prompt'
                            onClick={() => onSelectPrompt('new')}
                            disabled={!canEditPrompt(selectedLibrary.userRole)}
                            aria-label='Create Prompt'
                        >
                            <AddIcon color={palette.primary.contrastText} />
                        </CreatePromptButton>
                    </InfoTooltip>
                </BottomSection>
            </Container>

            <PromptSharing
                libraryId={selectedLibrary.id}
                role={selectedLibrary.userRole}
                open={!!sharingAnchor}
                anchorEl={sharingAnchor}
                onClose={() => setSharingAnchor(null)}
            />
        </>
    )
}

export default PromptLibraryWrapper
