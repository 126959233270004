import { usePromptContext } from '../../context/PromptContext'
import { CircularProgress, Drawer, styled, Typography } from '@mui/material'
import UnstyledButton from '../common/UnstyledButton'
import { UUID } from '../../types'
import { validateLibraryImportFile } from '../../utils/documents'
import { ChangeEvent, useRef } from 'react'
import { libraryImportEndpoint } from '../../endpoints'
import { useUserContext } from '../../context/UserContext'
import { usePost } from '../../hooks/usePost'
import { drawerWidth } from './PromptLibraryModal'
import TransparentButton from '../common/TransparentButton'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        padding: '30px 0 50px 0',
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px 0 0 10px',
        width: drawerWidth,
        height: '80dvh',
        overflowY: 'auto',
        position: 'absolute',
        transition: 'none',
        borderRight: `2px solid ${theme.palette.divider}`,
    },
}))

const DrawerHeader = styled(Typography)({
    padding: '0 0 12px 30px',
})

const DrawerWrapper = styled('div')({
    overflowY: 'auto',
})

const DrawerOption = styled(UnstyledButton, { shouldForwardProp: forwardPropsWithTransient })<{ $isSelectedLibrary: boolean }>(
    ({ theme, $isSelectedLibrary }) => ({
        cursor: 'pointer',
        padding: '8px 36px',
        width: '100%',
        backgroundColor: $isSelectedLibrary ? theme.palette.action.selected : 'transparent',
        '&:hover': {
            transition: 'background-color 0.2s',
            backgroundColor: theme.palette.action.hover,
        },
    })
)

const DrawerButton = styled(TransparentButton)({
    padding: '2px 10px',
    transition: 'background-color 0.2s, opacity 0.3s',
    margin: '8px 94px 8px 14px',
})

const ImportButton = styled(DrawerButton)({
    position: 'absolute',
    bottom: '10px',
    margin: '12px 0 6px 0',
    alignSelf: 'center',
    padding: '2px 20px',
})

const StyledCircularLoading = styled(CircularProgress)({
    position: 'absolute',
    bottom: '12px',
    margin: '12px 0 6px 0',
    alignSelf: 'center',
})

const HiddenInput = styled('input')({
    display: 'none',
})

const PromptLibrarySidebar = () => {
    const { promptLibraries, selectedLibrary, onLibraryChange, refreshLibraries, refreshPromptList, onSelectPrompt, onEditLibrary, onEditPrompt } =
        usePromptContext()
    const { isMaintainer } = useUserContext()

    const hiddenInputRef = useRef<HTMLInputElement>(null)

    const [uploadLibrary, libraryUploadLoading] = usePost<FormData, boolean>(libraryImportEndpoint)

    const handleLibrarySelection = (id: UUID) => {
        onLibraryChange(id)
        onSelectPrompt(null)
        onEditLibrary(false)
        onEditPrompt(false)
    }

    const handleLibraryImport = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const file = validateLibraryImportFile(files)

        if (file) {
            const payload = new FormData()
            payload.append('file', file)

            const success = await uploadLibrary(payload)
            if (success) {
                refreshLibraries()
                refreshPromptList()
            }
        }
    }

    return (
        <StyledDrawer variant='permanent' anchor='left'>
            <DrawerHeader variant='h6'>Prompt Libraries</DrawerHeader>
            <DrawerWrapper>
                {promptLibraries?.map(library => (
                    <DrawerOption onClick={() => handleLibrarySelection(library.id)} key={library.id} $isSelectedLibrary={library.id === selectedLibrary?.id}>
                        <Typography variant='body1'>{library.title}</Typography>
                    </DrawerOption>
                ))}
            </DrawerWrapper>
            <DrawerButton onClick={() => onLibraryChange('new')}>
                <Typography variant='h6'>+ New Library</Typography>
            </DrawerButton>
            {isMaintainer && (
                <>
                    {libraryUploadLoading ? (
                        <StyledCircularLoading size={24} />
                    ) : (
                        <ImportButton onClick={() => hiddenInputRef.current?.click()} disabled={libraryUploadLoading} aria-label='import library'>
                            <Typography variant='h6'>Import Library</Typography>
                        </ImportButton>
                    )}

                    <HiddenInput ref={hiddenInputRef} type='file' onChange={handleLibraryImport} />
                </>
            )}
        </StyledDrawer>
    )
}

export default PromptLibrarySidebar
