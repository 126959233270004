import { styled } from '@mui/material'
import CenteredContainer from './CenteredContainer'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

interface ColorProps {
    $primaryColor?: boolean
}

const Spinner = styled('div', {
    shouldForwardProp: forwardPropsWithTransient,
})<ColorProps>(({ $primaryColor, theme }) => ({
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    display: 'inline-block',
    borderTop: `3px solid ${$primaryColor ? theme.palette.primary.main : theme.palette.background.default}`,
    borderRight: '3px solid transparent',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite',

    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}))

const Bar = styled('span', {
    shouldForwardProp: forwardPropsWithTransient,
})<ColorProps>(({ $primaryColor, theme }) => ({
    width: '100%',
    height: '4.8px',
    display: 'inline-block',
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.15)',
    overflow: 'hidden',

    '&::after': {
        content: '""',
        width: '192px',
        height: '4.8px',
        background: $primaryColor ? theme.palette.primary.main : theme.palette.background.default,
        position: 'absolute',
        top: '0',
        left: '0',
        boxSizing: 'border-box',
        animation: 'animloader 2s linear infinite',

        '@keyframes animloader': {
            '0%': {
                left: '0',
                transform: 'translateX(-100%)',
            },
            '100%': {
                left: '100%',
                transform: 'translateX(0%)',
            },
        },
    },
}))

interface LoadingProps extends ColorProps {
    className?: string
    fullSize?: boolean
    bar?: boolean
    primaryColor?: boolean
}

const Loading = ({ className, fullSize, primaryColor, bar }: LoadingProps) => {
    const Loader = bar ? Bar : Spinner

    return fullSize ? (
        <CenteredContainer className={className}>
            <Loader $primaryColor={primaryColor} role='progressbar' aria-label='loading' />
        </CenteredContainer>
    ) : (
        <Loader className={className} $primaryColor={primaryColor} role='progressbar' aria-label='loading' />
    )
}

export default Loading
