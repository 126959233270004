import { styled } from '@mui/material'
import { DocumentUserMessage } from '../types'
import MarkdownMessage from './common/markdown/MarkdownMessage'
import DocumentChip from './DocumentChip'
import { forwardPropsWithTransient } from '../utils/muiUtils'

interface StyledDocumentChipProps {
    $applyTopMargin: boolean
}

const StyledDocumentChip = styled(DocumentChip, {
    shouldForwardProp: forwardPropsWithTransient,
})<StyledDocumentChipProps>(({ $applyTopMargin }) => ({
    ...($applyTopMargin && {
        marginTop: '20px',
    }),
}))

interface DocumentMessageProps {
    message: DocumentUserMessage
}

const DocumentMessage = ({ message }: DocumentMessageProps) => {
    const text = message.text ?? ''

    return (
        <>
            <MarkdownMessage>{text}</MarkdownMessage>
            {message.documents.length > 0
                ? message.documents.map((document, i) => <StyledDocumentChip key={document.id} $applyTopMargin={i === 0 && text !== ''} document={document} />)
                : 'Documents seem to be missing, please report an issue if this is unexpected'}
        </>
    )
}

export default DocumentMessage
