import { InputHTMLAttributes } from 'react'
import { styled } from '@mui/material'

const Input = styled('input')(({ theme }) => ({
    marginRight: '10px',
    accentColor: theme.palette.primary.main,
}))

interface RadioOptionProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
}

const RadioOption = ({ className, label, ...rest }: RadioOptionProps) => (
    <label className={className}>
        <Input type='radio' {...rest} />
        {label}
    </label>
)

export default RadioOption
