import { styled } from '@mui/material'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'
import { MouseEventHandler, ReactNode } from 'react'
import { Element } from 'hast'

const StyledSpan = styled('span', { shouldForwardProp: forwardPropsWithTransient })<{ $isActive?: boolean }>(({ theme, $isActive }) => ({
    '&.reference-SUPPORTED': {
        ':hover': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.success.dark : theme.palette.success.light,
            cursor: 'pointer',
        },
        ':focus': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.success.dark : theme.palette.success.light,
        },
        backgroundColor: $isActive ? theme.palette.success.main : 'transparent',
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textDecorationThickness: '1px',
    },
    '&.reference-REFUTED': {
        ':hover': {
            backgroundColor: theme.palette.error.main,
            cursor: 'pointer',
        },
        ':focus': {
            backgroundColor: theme.palette.error.main,
        },
        backgroundColor: $isActive ? theme.palette.error.main : theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light,
    },
    '&.reference-UNSUPPORTED': {
        ':hover': {
            backgroundColor: theme.palette.warning.main,
            cursor: 'pointer',
        },
        ':focus': {
            backgroundColor: theme.palette.warning.main,
        },
        backgroundColor: $isActive ? theme.palette.warning.main : theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.light,
    },
    '&.reference-BASIC': {
        ':hover': {
            backgroundColor: theme.palette.info.main,
            cursor: 'pointer',
        },
        ':focus': {
            backgroundColor: theme.palette.info.main,
        },
        backgroundColor: $isActive ? theme.palette.info.main : 'transparent',
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textDecorationThickness: '1px',
    },
    '&.reference-FALLBACK': {
        ':hover': {
            backgroundColor: theme.palette.info.main,
            cursor: 'pointer',
        },
        ':focus': {
            backgroundColor: theme.palette.info.main,
        },
        backgroundColor: $isActive ? theme.palette.info.main : 'transparent',
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textDecorationThickness: '1px',
    },
}))

interface ComponentWithReferencesProps {
    node: Element | undefined
    childComponents: ReactNode | string | null
    selectedSpanId?: string
    handleClick?: MouseEventHandler
}

const ComponentWithReferences = ({ node, childComponents, selectedSpanId, handleClick }: ComponentWithReferencesProps) => {
    const spanId = node?.properties?.['id']

    return (
        <StyledSpan onClick={handleClick} tabIndex={0} $isActive={spanId === selectedSpanId} {...node?.properties}>
            {childComponents}
        </StyledSpan>
    )
}

export default ComponentWithReferences
