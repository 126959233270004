import InfoTooltip from '../common/tooltips/InfoTooltip'
import MiniIconButton from '../common/MiniIconButton'
import { styled, Theme } from '@mui/material'

export const ButtonTooltipWrapper = styled(InfoTooltip)({
    marginLeft: '4px',
})

const getLibraryButtonStyles = (theme: Theme) => ({
    display: 'inline-flex',
    '&:hover:not([disabled])': {
        backgroundColor: theme.palette.secondary.main,
    },
})

export const OuterTabButton = styled(MiniIconButton)(({ theme }) => ({
    ...getLibraryButtonStyles(theme),
    width: '27px',
    height: '27px',
    margin: '4px 0 0 4px',
    padding: '4px',
}))

export const InnerTabButton = styled(MiniIconButton)(({ theme }) => ({
    ...getLibraryButtonStyles(theme),
    height: '22px',
    width: '22px',
    padding: '0 0 2px 2px',
    verticalAlign: 'middle',
}))
