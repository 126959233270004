import { List, styled } from '@mui/material'
import { ChangeEvent, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { knowledgeBaseEndpoint, processKnowledgeBaseEndpoint } from '../../../endpoints'
import { useKnowledgeBaseDocumentUpload } from '../../../hooks/useDocumentUpload'
import { useGet } from '../../../hooks/useGet'
import { usePost } from '../../../hooks/usePost'
import { KBDocument, UUID } from '../../../types'
import Button from '../../common/Button'
import CenteredContainer from '../../common/CenteredContainer'
import Loading from '../../common/Loading'
import TextButton from '../../common/TextButton'
import Instructions from '../Instructions'
import KnowledgeBaseListItem from './KnowledgeBaseListItem'
import KnowledgeBaseUploadList from './KnowledgeBaseUploadList'
import { KnowledgeBaseEntry, KnowledgeBaseEntryPayload, ProcessEndpointPayload } from './types'
import { allowedKnowledgeBaseExtensions, pairInputFiles, validateKnowledgeBaseFiles } from './utils'

const Container = styled('div')({
    margin: '10px',
    height: '100%',
})

const StyledList = styled(List)({
    overflowY: 'auto',
    maxHeight: '75%',
    marginBottom: '24px',
})

const ControlButton = styled(TextButton)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const HiddenInput = styled('input')({
    display: 'none',
})

const GenerateContainer = styled('div')({
    position: 'absolute',
    bottom: 0,
})

interface KnowledgeBaseDisplayProps {
    botId: UUID
    readonly?: boolean
}

const KnowledgeBaseDisplay = ({ botId, readonly = true }: KnowledgeBaseDisplayProps) => {
    const [uploadFiles, uploading] = useKnowledgeBaseDocumentUpload(botId)
    const uploadRef = useRef<HTMLInputElement>(null)
    const [knowledgeBaseEntry, setKnowledgeBaseEntries] = useState<KnowledgeBaseEntry[]>([])

    const [kbDocuments, loading, refresh] = useGet<KBDocument[]>(knowledgeBaseEndpoint(botId))
    const [processBatch, processingBatch] = usePost<ProcessEndpointPayload, boolean>(processKnowledgeBaseEndpoint(botId))

    const handleAttach = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (!event.target.files || event.target.files.length === 0) {
            toast.warn('Please attach files to upload')
        } else {
            const validatedFiles = validateKnowledgeBaseFiles(Array.from(event.target.files))
            const newEntries = pairInputFiles(validatedFiles)
            setKnowledgeBaseEntries(knowledgeBaseEntry.concat(newEntries))
        }
    }

    const handleRemove = (id: UUID) => setKnowledgeBaseEntries(knowledgeBaseEntry.filter(input => input.id !== id))

    const handleUpload = async () => {
        if (knowledgeBaseEntry.length) {
            const allFiles = knowledgeBaseEntry.flatMap(({ original, processed }) => (processed ? [original, processed] : [original]))
            const uploadResults = await uploadFiles(allFiles)
            const uploadedIdsSet = new Set(uploadResults.uploadedIds)

            const payload = knowledgeBaseEntry.reduce((payload: KnowledgeBaseEntryPayload[], inputs) => {
                if (uploadedIdsSet.has(inputs.original.id)) {
                    payload.push({
                        name: inputs.original.file.name,
                        original: inputs.original.id,
                        processed: inputs.processed && uploadedIdsSet.has(inputs.processed.id) ? inputs.processed.id : undefined,
                    })
                }

                return payload
            }, [])
            const success = payload.length ? await processBatch({ inputs: payload }) : false

            if (success) {
                setKnowledgeBaseEntries([])
                refresh()
            }
        } else {
            toast.warn('Please attach files to upload')
        }
    }

    return (
        <>
            {loading ? (
                <CenteredContainer>
                    <Loading primaryColor fullSize />
                </CenteredContainer>
            ) : (
                <Container>
                    <Instructions>
                        {!kbDocuments ||
                            (kbDocuments.length < 1 ? (
                                <>This bot has no knowledge base associated with it.</>
                            ) : (
                                <>When chatting with the bot, it has access to the following documents:</>
                            ))}
                    </Instructions>
                    <StyledList>
                        {kbDocuments?.map(sourceDoc => (
                            <KnowledgeBaseListItem
                                key={sourceDoc.id}
                                sourceDoc={sourceDoc}
                                botId={botId}
                                onDeleteSuccess={refresh}
                                loading={processingBatch || uploading}
                                readonly={readonly}
                            />
                        ))}
                        <KnowledgeBaseUploadList loading={uploading} entries={knowledgeBaseEntry} handleRemove={handleRemove} />
                    </StyledList>
                    {!readonly && (
                        <>
                            <ControlButton disabled={false} onClick={() => uploadRef.current?.click()} aria-label='upload document'>
                                Add Documents
                                <HiddenInput
                                    ref={uploadRef}
                                    type='file'
                                    multiple
                                    onChange={handleAttach}
                                    accept={allowedKnowledgeBaseExtensions.map(e => `.${e}`).join(',')}
                                />
                            </ControlButton>
                            <GenerateContainer>
                                <Button onClick={handleUpload} aria-label='generate knowledge base'>
                                    Update Knowledge Base
                                </Button>
                            </GenerateContainer>
                        </>
                    )}
                </Container>
            )}
        </>
    )
}

export default KnowledgeBaseDisplay
