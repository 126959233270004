import { KeyboardEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'
import SendIcon from '../icons/SendIcon'
import Button from './common/Button'
import { mainElementMaxWidth, mediaQueries, scrollBarStyle } from './common/styles'
import { useMessageContext } from '../context/MessageContext'
import { useChatContext } from '../context/ChatContext'
import { toast } from 'react-toastify'
import { environmentVariables } from '../env'
import AttachIcon from '../icons/AttachIcon'
import Chip from './common/Chip'
import { useFileContext } from '../context/FileContext'
import { useAutosizeTextArea } from '../hooks/useAutosizeTextArea'
import HiddenAttachInput from './common/HiddenAttachInput'
import BookIcon from '../icons/BookIcon'
import PromptLibraryModal from './prompt-library/PromptLibraryModal'
import useIsMobile from '../hooks/useIsMobile'
import { Tooltip, Typography, useTheme } from '@mui/material'
import TransparentButton from './common/TransparentButton'
import InfoTooltip from './common/tooltips/InfoTooltip'

const MainContainer = styled.section`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: flex-end;
    max-height: 300px;
    width: calc(100% - 24px);
    margin: 8px 24px 24px 24px;
    ${mainElementMaxWidth}
`

const Container = styled.div`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.colors.divider};
    border-radius: 12px;
    width: 100%;
    margin: 0 auto;
    ${mainElementMaxWidth}

    @media ${mediaQueries.mobile} {
        margin: 12px;
    }

    &:focus-within {
        border: 1px solid ${props => props.theme.colors.secondary};
    }
`

const inputPadding = 12
const inputLineHeight = 19

const InputContainer = styled.div`
    padding: ${inputPadding}px ${inputPadding}px 0 ${inputPadding}px;
    flex: 80;
    max-height: 250px;

    display: flex;
    flex-direction: column;
`

const TextArea = styled.textarea`
    height: 100%;
    border: none;
    overflow-wrap: anywhere;
    outline: none;
    resize: none;
    line-height: ${inputLineHeight}px;
    min-height: ${2 * inputLineHeight}px;
    background-color: transparent;
    color: ${props => props.theme.colors.text};
    ${scrollBarStyle}
    ::placeholder {
        // using the disabled color here for consistency.
        color: ${props => props.theme.colors.disabled};
        font-style: italic;
    }
`

const ChipContainer = styled.div`
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-top: ${inputPadding}px;
    padding: 5px 0;
    overflow-y: auto;
    ${scrollBarStyle}
`

const StyledChip = styled(Chip)`
    margin: 5px;
    border: 1px dashed ${props => props.theme.colors.divider};
`

const Controls = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-end;
`

const ControlButton = muiStyled(TransparentButton)({
    borderRadius: '8px',
    padding: '4px',
    width: '36px',
    height: '36px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
})

// Tooltip won't get mouse events if button is actually disabled
const AttachButton = muiStyled(ControlButton)({
    backgroundColor: 'unset',
    marginLeft: '8px',
})

const CenteredTooltipText = muiStyled(Typography)({
    textAlign: 'center',
    width: '200px',
})

const PromptButton = muiStyled(ControlButton)({
    backgroundColor: 'unset',
})

const InputButtonSpan = styled('span')({
    minHeight: '36px',
    display: 'inline-block',
})

const SendButton = muiStyled(Button)({
    marginRight: '8px',
    borderRadius: '8px',
    padding: '4px',
    width: '36px',
    height: '36px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
})

interface InputAreaProps {
    className?: string
}

const InputArea = ({ className }: InputAreaProps) => {
    const { palette } = useTheme()
    const { currentChat } = useChatContext()
    const { sendMessage, sendDocument } = useMessageContext()
    const { files, uploading, onRemove } = useFileContext()
    const uploadRef = useRef<HTMLInputElement>(null)
    const [textAreaRef, input, setInput] = useAutosizeTextArea(inputLineHeight)
    const [showPromptLibrary, setShowPromptLibrary] = useState<boolean>(false)

    const isMobile = useIsMobile()

    if (!currentChat) {
        return null
    }

    const sendMessageToCurrentChat = sendMessage(currentChat.id)
    const sendDocumentToCurrentChat = sendDocument(currentChat.id)

    const handleSend = () => {
        if (input.length > environmentVariables.userMessageCharacterLimit) {
            toast.warn(`Please reduce message length. You are ${input.length - environmentVariables.userMessageCharacterLimit} characters over the limit`)
        } else if (files) {
            sendDocumentToCurrentChat(input)
            setInput('')
        } else if (input.trim() === '') {
            toast.warn('Please enter a message to send')
        } else {
            // TODO: Handle failure sending message
            sendMessageToCurrentChat(input)
            setInput('')
        }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        const { key, shiftKey } = event

        if (key === 'Enter' && !shiftKey) {
            event.preventDefault()
            currentChat.status === 'active' && handleSend()
        }
    }

    return (
        <MainContainer className={className}>
            <Container>
                <InputContainer>
                    <TextArea
                        ref={textAreaRef}
                        value={input}
                        placeholder='Shift + Enter to add a new line'
                        onChange={({ target: { value } }) => setInput(value)}
                        onKeyDown={handleKeyDown}
                        rows={1}
                    />
                    {files && !uploading && (
                        <ChipContainer>
                            {files.map(fileWithId => (
                                <StyledChip
                                    key={fileWithId.id}
                                    title={fileWithId.file.name}
                                    iconTitle='Remove'
                                    label={fileWithId.file.name}
                                    onIconClick={() => onRemove(fileWithId.id)}
                                />
                            ))}
                        </ChipContainer>
                    )}
                </InputContainer>

                <Controls>
                    <div>
                        <Tooltip
                            title={
                                currentChat.bot.supportsDocumentUpload ? (
                                    'Attach a file'
                                ) : (
                                    <CenteredTooltipText>
                                        <strong>{currentChat.bot.name}</strong> does not support document uploads
                                    </CenteredTooltipText>
                                )
                            }
                        >
                            <InputButtonSpan>
                                <AttachButton
                                    disabled={!currentChat.bot.supportsDocumentUpload}
                                    onClick={() => currentChat.bot.supportsDocumentUpload && uploadRef.current?.click()}
                                    aria-label='upload document'
                                >
                                    <AttachIcon disabled={!currentChat.bot.supportsDocumentUpload} />
                                    <HiddenAttachInput ref={uploadRef} />
                                </AttachButton>
                            </InputButtonSpan>
                        </Tooltip>
                        {!isMobile && (
                            <Tooltip title={'Prompt Library'}>
                                <InputButtonSpan>
                                    <PromptButton onClick={() => setShowPromptLibrary(true)} aria-label='prompt library button'>
                                        <BookIcon width={26} height={24} color={palette.text.primary} strokeWidth={2} />
                                    </PromptButton>
                                </InputButtonSpan>
                            </Tooltip>
                        )}
                    </div>
                    <InfoTooltip title='Send'>
                        <SendButton disabled={currentChat.status !== 'active'} onClick={handleSend} aria-label='send message'>
                            <SendIcon disabled={currentChat.status !== 'active'} />
                        </SendButton>
                    </InfoTooltip>
                </Controls>
            </Container>
            <PromptLibraryModal show={showPromptLibrary} onClose={() => setShowPromptLibrary(false)} onSetInput={setInput} />
        </MainContainer>
    )
}

export default InputArea
