import { useMsal } from '@azure/msal-react'
import { selectAccountLoginRequest } from '../authConfig'
import LogoIcon from '../icons/LogoIcon'
import MicrosoftIcon from '../icons/MicrosoftIcon'
import UnstyledButton from './common/UnstyledButton'
import { InteractionStatus } from '@azure/msal-browser'
import { toast } from 'react-toastify'
import useDebounce from '../hooks/useDebounce'
import Loading from './common/Loading'
import { styled, Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
}))

const LoginContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    padding: '22px',
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
}))

const IconContainer = styled('div')({
    position: 'absolute',
    top: '36px',
})

const MicrosoftButton = styled(UnstyledButton)(({ theme }) => ({
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    margin: '32px auto 0 auto',
    padding: '20px',

    '& h6': {
        color: theme.palette.secondary.contrastText,
    },
}))

const StyledLoading = styled(Loading)({
    margin: '32px 0',
})

const Login = () => {
    const { instance, inProgress } = useMsal()
    const debouncedInteractionStatus = useDebounce(inProgress, 50)

    const handleMicrosoftLogin = async () => {
        if (inProgress !== InteractionStatus.None) {
            toast.warn('Authentication already in progress, please check for the popup window or try refreshing the page')
        } else {
            // Errors are caught in the effect in App
            await instance.loginPopup(selectAccountLoginRequest)
        }
    }

    return (
        <Container>
            <IconContainer>
                <LogoIcon />
            </IconContainer>
            <LoginContainer>
                <Typography variant='h2' color='text.primary'>
                    Sign In
                </Typography>
                <Typography color='text.primary'>You can sign in using the Microsoft account that has been linked to SpringLaw.</Typography>
                {debouncedInteractionStatus === InteractionStatus.None ? (
                    <MicrosoftButton type='button' onClick={handleMicrosoftLogin}>
                        <MicrosoftIcon />
                        <Typography variant='h6'>Continue with Microsoft</Typography>
                    </MicrosoftButton>
                ) : (
                    <StyledLoading primaryColor />
                )}
            </LoginContainer>
        </Container>
    )
}

export default Login
