import { styled, Typography } from '@mui/material'
import { useGet } from '../../hooks/useGet'
import { libraryExportEndpoint } from '../../endpoints'
import { UUID } from '../../types'
import TransparentButton from '../common/TransparentButton'

const Button = styled(TransparentButton)(() => ({
    transition: 'background-color 0.2s, opacity 0.3s',
    border: 'none',
    padding: '2px 20px',
}))

interface LibraryTabProps {
    libraryId: UUID
}

const ExportLibraryButton = ({ libraryId }: LibraryTabProps) => {
    const [, exportLoading, getLibraryExport] = useGet<Blob>(libraryExportEndpoint(libraryId), {
        requestOnRender: false,
        responseType: 'blob',
    })

    return (
        <Button onClick={getLibraryExport} type='button' aria-label='Export Library' disabled={exportLoading}>
            <Typography variant='h6'>Export Library</Typography>
        </Button>
    )
}

export default ExportLibraryButton
