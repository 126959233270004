import { InteractionRequiredAuthError } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import { useCallback } from 'react'
import { loginRequest } from '../authConfig'

export const useAcquireUserCredentials = () => {
    const { instance } = useMsal()

    const acquireUserCredentials = useCallback(async () => {
        try {
            await instance.initialize(loginRequest)
            const response = await instance.acquireTokenSilent(loginRequest)
            const credentials = {
                accessToken: response.accessToken,
                userId: response.account.localAccountId,
            }
            return credentials
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                const response = await instance.acquireTokenPopup(loginRequest)
                const credentials = {
                    accessToken: response.accessToken,
                    userId: response.account.localAccountId,
                }
                return credentials
            } else {
                throw error
            }
        }
    }, [instance])

    return acquireUserCredentials
}
