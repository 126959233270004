import { MessageBlob } from '../MessageDisplay'
import Button from '../common/Button'
import Loading from '../common/Loading'
import Breadcrumb from '../common/Breadcrumb'
import PromptMetaButtons from './PromptMetaButtons'
import { usePromptContext } from '../../context/PromptContext'
import MarkdownMessage from '../common/markdown/MarkdownMessage'
import { createDisplayedPrompt } from './promptUtils'
import { styled, Typography } from '@mui/material'
import { useDarkModeContext } from '../../context/DarkModeContext'

export const PromptDetailWrapper = styled('div')({
    height: '85%',
    overflowY: 'auto',
    padding: '0 20px',
    margin: '0 -15px',
})

const DetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
})

export const MessageContainer = styled('div')({
    width: '80%',
})

export const UsePromptButton = styled(Button)({
    marginTop: '50px',
})

export const HeaderRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
})

export const SubmissionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginRight: '100px',
})

export const StyledMessageBlob = styled(MessageBlob)(({ theme, isDarkMode }) => ({
    marginTop: '20px',
    maxHeight: '45%',
    width: 'inherit',
    overflowY: 'auto',
    border: `24px solid ${isDarkMode ? theme.palette.primary.main : theme.palette.primary.dark}`,
    padding: '0px 12px 0px 0px',
    margin: '24px auto 0 auto',
}))

interface SimplePromptDetailProps {
    onUsePrompt: (promptMessage: string) => void
}

const SimplePromptDetail = ({ onUsePrompt }: SimplePromptDetailProps) => {
    const { prompt, onSelectPrompt } = usePromptContext()
    // TODO remove this once markdownMessage uses Mui theme colours
    const { isDarkMode } = useDarkModeContext()

    if (!prompt) {
        return <Loading fullSize={true} primaryColor={true} />
    }

    const displayedPrompt = createDisplayedPrompt(prompt.messageList)

    return (
        <>
            <Breadcrumb onClick={() => onSelectPrompt(null)} label='Back to Library' />
            <PromptDetailWrapper>
                <DetailsContainer>
                    <MessageContainer>
                        <Typography variant='h2'>{prompt.name}</Typography>
                        <HeaderRow>
                            <Typography>{prompt.description}</Typography>
                            <PromptMetaButtons prompt={prompt} />
                        </HeaderRow>
                        <StyledMessageBlob userMessage={true} isDarkMode={isDarkMode}>
                            <MarkdownMessage>{displayedPrompt}</MarkdownMessage>
                        </StyledMessageBlob>
                        <SubmissionContainer>
                            <UsePromptButton onClick={() => onUsePrompt(displayedPrompt)} title='Use Prompt' type='submit' aria-label='use prompt button'>
                                Use Prompt
                            </UsePromptButton>
                        </SubmissionContainer>
                    </MessageContainer>
                </DetailsContainer>
            </PromptDetailWrapper>
        </>
    )
}

export default SimplePromptDetail
