import { useState } from 'react'
import { NewBotOption } from '../../apiTypes'
import Button from '../common/Button'
import Input from '../common/Input'
import VerticalRadio from '../common/VerticalRadio'
import WithLabels from '../common/WithLabels'
import { styled } from '@mui/material'
import TextArea from '../common/TextArea'

const NewBotControls = styled('div')({
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'column',
})

const StyledInput = styled(Input)({
    flexGrow: 1,
    margin: '10px 10px 10px 0',
})

const Description = styled(TextArea)({
    flexGrow: 1,
    margin: '10px 10px 10px 0',
    height: '50px',
    borderRadius: '6px',
    resize: 'vertical',
    padding: '10px',
})

const StyledButton = styled(Button)({
    width: 'fit-content',
})

const BotTypeSection = styled('div')({
    display: 'flex',
})

const BotTypeList = styled('div')({
    marginLeft: '10px',
})

interface NewBotDetailsProps {
    botId: string
    botOptions: NewBotOption[]
    onOptionChange: (id: string) => void
    onCreate: (botId: string, name: string, description: string) => void
}

const NewBotDetailsPage = ({ botId, botOptions, onOptionChange, onCreate }: NewBotDetailsProps) => {
    const option = botOptions.find(bo => bo.className === botId) ?? botOptions[0]

    const [name, setName] = useState(option?.name ?? '')
    const [description, setDescription] = useState(option?.description ?? '')

    const newBotInfoReady = name.length > 0 && description.length > 0

    return (
        <NewBotControls>
            <p>
                To create a new tool, you must first select a tool type to start from. This will prefill the name & description fields below with the defaults
                for that tool type, and upon creation, will use the default configuration & AI engines, for that tool type. You may override the name &
                description fields upon creation, and the configuration after. The tool type choice determines the UI which will be shown in each chat with it.
            </p>
            <BotTypeSection>
                <label>Choose a tool type:</label>
                <BotTypeList>
                    <VerticalRadio options={botOptions.map(o => ({ label: o.name, value: o.className }))} value={botId} onChange={onOptionChange} />
                </BotTypeList>
            </BotTypeSection>
            <WithLabels label='Name'>
                <StyledInput aria-label='new tool name' placeholder='Name of the new tool' value={name} onChange={({ target: { value } }) => setName(value)} />
            </WithLabels>
            <WithLabels label='Description'>
                <Description
                    aria-label='new tool description'
                    placeholder='Description of the new tool'
                    value={description}
                    onChange={({ target: { value } }) => setDescription(value)}
                />
            </WithLabels>
            <StyledButton type='submit' aria-label='create tool' onClick={() => onCreate(botId, name, description)} disabled={!newBotInfoReady}>
                Create
            </StyledButton>
        </NewBotControls>
    )
}

const NewBotDetails = (props: NewBotDetailsProps) => <NewBotDetailsPage key={props.botId} {...props} />

export default NewBotDetails
