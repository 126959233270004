import { styled } from '@mui/material'

const Header = styled('header')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: '16px 0 0 0',
}))

export default Header
