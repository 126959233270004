import { styled } from '@mui/material'
import UnstyledButton from './UnstyledButton'
import { zIndexes } from './styles'

const MiniIconButton = styled(UnstyledButton)(({ theme }) => ({
    zIndex: `${zIndexes.button}`,
    border: 'none',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    transition: 'all 0.3s',

    ':hover:not([disabled])': {
        backgroundColor: theme.palette.action.disabled,
    },

    ':focus': {
        outline: 'none',
    },

    ':disabled': {
        cursor: 'default',
    },
}))

export default MiniIconButton
