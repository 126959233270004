import { styled } from '@mui/material'
import ChatHeader from './ChatHeader'
import ChatWindow from './ChatWindow'
import { areaLayoutStyles } from './common/styles'
import InputArea from './InputArea'
import UploadArea from './common/UploadArea'
import DownloadChat from './common/DownloadChat'
import { useChatContext } from '../context/ChatContext'
import WithValidation from './WithValidation'

const StyledChatHeader = styled(ChatHeader)(({ theme }) => ({
    ...areaLayoutStyles(theme).header,
}))

const StyledChatWindow = styled(ChatWindow)(({ theme }) => ({
    ...areaLayoutStyles(theme).content,
}))

const StyledInputArea = styled(InputArea)(({ theme }) => ({
    ...areaLayoutStyles(theme).footer,
}))

const StyledUploadArea = styled(UploadArea)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '75%',
    height: `calc(75vh - ${areaLayoutStyles(theme).headerHeight})`,
}))

const ChatLayout = () => {
    const { currentChat } = useChatContext()

    return (
        <>
            <StyledChatHeader>{currentChat && <DownloadChat chatId={currentChat.id} chatTitle={currentChat.title || 'Chat'} />}</StyledChatHeader>
            <StyledChatWindow />
            <WithValidation>
                <StyledInputArea />
            </WithValidation>
            <StyledUploadArea />
        </>
    )
}

export default ChatLayout
