import {
    AuthError,
    BrowserAuthError,
    BrowserAuthErrorCodes,
    BrowserCacheLocation,
    Configuration,
    PopupRequest,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser'
import { environmentVariables } from './env'

const { clientId, redirectUri, scopes } = environmentVariables

export const msalConfig: Configuration = {
    auth: {
        clientId,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
    },
}

export const loginRequest: PopupRequest | RedirectRequest | SilentRequest = {
    scopes,
}

export const selectAccountLoginRequest: PopupRequest | RedirectRequest | SilentRequest = { ...loginRequest, prompt: 'select_account' }

const ignorableErrorCodes = [BrowserAuthErrorCodes.userCancelled]

export const isIgnorableError = (error: unknown) => error instanceof AuthError && ignorableErrorCodes.includes(error.errorCode)

export const handledErrorCodes = {
    popup: BrowserAuthErrorCodes.popupWindowError,
    empty: BrowserAuthErrorCodes.emptyWindowError,
    client: 'invalid_client', // Server error, seems to only be typed as string in msal-common
    inProgress: BrowserAuthErrorCodes.interactionInProgress,
} as const

const isBrowserAuthError = (value: unknown): value is BrowserAuthError =>
    typeof value === 'object' && !!value && 'name' in value && value.name === 'BrowserAuthError'

export const isResolvedAuthError = (error: unknown) =>
    isBrowserAuthError(error) && ignorableErrorCodes.concat(Object.values(handledErrorCodes)).some(errorCode => error.errorCode === errorCode)
