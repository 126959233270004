import { useCallback } from 'react'
import { useAcquireUserCredentials } from './useAcquireUserCredentials'

export const useAuthHeaders = () => {
    const acquireUserCredentials = useAcquireUserCredentials()

    const generateHeaders = useCallback(async () => {
        const headers = new Headers()
        const tokens = await acquireUserCredentials()

        headers.append('Authorization', `Bearer ${tokens.accessToken}`)
        headers.append('user_id', tokens.userId)

        return headers
    }, [acquireUserCredentials])

    return generateHeaders
}
