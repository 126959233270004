import { Popover, PopoverProps, styled, tooltipClasses, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import BinIcon from '../../icons/BinIcon'
import CrossIcon from '../../icons/CrossIcon'
import InfoIcon from '../../icons/InfoIcon'
import { Atomic, EvidenceClass } from '../../types'
import { forwardPropsWithTransient } from '../../utils/muiUtils'
import { zIndexes } from '../common/styles'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import TransparentButton from '../common/TransparentButton'
import ReferenceList from './components/ReferenceList'
import FloatingCard from '../common/tooltips/FloatingCard'

const StyledReferenceCard = styled('div', { shouldForwardProp: forwardPropsWithTransient })<{ $type: EvidenceClass }>(({ theme, $type }) => ({
    width: '480px',
    display: 'flex',
    flexDirection: 'column',
    padding: '26px 12px 24px 24px',
    borderRadius: '12px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid`,
    gap: '4px',
    zIndex: zIndexes.references,
    borderColor: {
        SUPPORTED: theme.palette.success.main,
        REFUTED: theme.palette.error.main,
        UNSUPPORTED: theme.palette.warning.main,
        FALLBACK: theme.palette.info.main,
        BASIC: theme.palette.info.main,
    }[$type],
}))

const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingRight: '12px',
    maxHeight: '320px',
    overflowY: 'auto',
})

const SectionHeader = styled(Typography, { shouldForwardProp: forwardPropsWithTransient })<{ $type: EvidenceClass }>(({ theme, $type }) => ({
    fontWeight: 'bold',
    margin: '3px 0px 3px 0px',
    color: {
        SUPPORTED: theme.palette.mode === 'dark' ? theme.palette.success.main : theme.palette.success.dark,
        REFUTED: theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.error.dark,
        UNSUPPORTED: theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.dark,
        FALLBACK: theme.palette.mode === 'dark' ? theme.palette.info.light : theme.palette.info.dark,
        BASIC: theme.palette.mode === 'dark' ? theme.palette.info.light : theme.palette.info.dark,
    }[$type],
    textTransform: 'uppercase',
}))

const SecondaryText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const TitleRowContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

const StyledReferenceButton = styled(TransparentButton, { shouldForwardProp: forwardPropsWithTransient })<{ $variant: 'retry' | 'delete' }>(
    ({ theme, $variant }) => ({
        display: 'flex',
        visibility: 'hidden', // TODO: remove once button is enabled
        gap: '5px',
        alignItems: 'center',
        color: $variant === 'retry' ? theme.palette.info.dark : theme.palette.error.dark,
        borderRadius: '20px',
        padding: '1px 8px',

        '&:hover': {
            backgroundColor: $variant === 'retry' ? theme.palette.info.light : theme.palette.error.light,
        },

        svg: {
            width: '12px',
            height: '12px',
            paddingBottom: '2px',
        },
    })
)

const StyledCloseButton = styled(TransparentButton)({
    padding: '2px',
    borderRadius: '50%',
    position: 'absolute',
    top: '6px',
    right: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const InfoTooltipWithHyperlink = styled(FloatingCard)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.common.white,
    },
}))

interface ReferenceCardProps {
    atomic: Atomic
    handleClose: () => void
    anchorEl: Element | null
}

const ReferenceCard = ({ atomic, handleClose, anchorEl }: ReferenceCardProps) => {
    const { palette } = useTheme()
    const [anchorPosition, setAnchorPosition] = useState<'top' | 'bottom'>('bottom')

    useEffect(() => {
        if (anchorEl) {
            const rect = anchorEl.getBoundingClientRect()
            const spaceAbove = rect.top
            const shouldPositionAbove = spaceAbove >= 300
            setAnchorPosition(shouldPositionAbove ? 'top' : 'bottom')
        }
    }, [anchorEl])

    const popoverProps: PopoverProps = {
        open: anchorEl !== null,
        anchorEl,
        onClose: handleClose,
        anchorOrigin: {
            vertical: anchorPosition,
            horizontal: 'center',
        },
        transformOrigin: {
            vertical: anchorPosition === 'top' ? 'bottom' : 'top',
            horizontal: 'center',
        },
    }

    switch (atomic.evidenceClass) {
        case 'SUPPORTED':
            return (
                <Popover {...popoverProps}>
                    <StyledReferenceCard $type={atomic.evidenceClass}>
                        <StyledCloseButton onClick={handleClose} aria-label='close card'>
                            <CrossIcon color={palette.text.secondary} height={15} width={15} />
                        </StyledCloseButton>
                        <InnerContainer>
                            <TitleRowContainer>
                                <SectionHeader $type={atomic.evidenceClass} variant='caption'>
                                    SUPPORTING REFERENCES:
                                </SectionHeader>
                                <InfoTooltip title='The below document references support the highlighted statement'>
                                    <InfoIcon />
                                </InfoTooltip>
                            </TitleRowContainer>
                            <ReferenceList references={atomic.references} />
                        </InnerContainer>
                    </StyledReferenceCard>
                </Popover>
            )
        case 'REFUTED':
            return (
                <Popover {...popoverProps}>
                    <StyledReferenceCard $type={atomic.evidenceClass}>
                        <StyledCloseButton onClick={handleClose} aria-label='close card'>
                            <CrossIcon color={palette.text.secondary} height={15} width={15} />
                        </StyledCloseButton>
                        <InnerContainer>
                            <TitleRowContainer>
                                <SectionHeader $type={atomic.evidenceClass} variant='caption'>
                                    CONTRADICTORY REFERENCES:
                                </SectionHeader>
                                <InfoTooltip title='The below document references contradict the highlighted statement'>
                                    <InfoIcon />
                                </InfoTooltip>
                            </TitleRowContainer>
                            <ReferenceList references={atomic.references} />
                        </InnerContainer>
                    </StyledReferenceCard>
                </Popover>
            )
        case 'UNSUPPORTED':
            return (
                <Popover {...popoverProps}>
                    <StyledReferenceCard $type={atomic.evidenceClass}>
                        <StyledCloseButton onClick={handleClose} aria-label='close card'>
                            <CrossIcon color={palette.text.secondary} height={15} width={15} />
                        </StyledCloseButton>
                        <TitleRowContainer>
                            <SectionHeader $type={atomic.evidenceClass} variant='caption'>
                                UNSUPPORTED STATEMENT
                            </SectionHeader>
                            <StyledReferenceButton $variant='delete' disabled>
                                <BinIcon fill={palette.error.dark} />
                                Delete
                            </StyledReferenceButton>
                        </TitleRowContainer>
                        <SecondaryText variant='caption'>No supporting references in the uploaded document(s)</SecondaryText>
                    </StyledReferenceCard>
                </Popover>
            )
        case 'FALLBACK':
            return (
                <Popover {...popoverProps}>
                    <StyledReferenceCard $type={atomic.evidenceClass}>
                        <StyledCloseButton onClick={handleClose} aria-label='close card'>
                            <CrossIcon color={palette.text.secondary} height={15} width={15} />
                        </StyledCloseButton>
                        <InnerContainer>
                            <TitleRowContainer>
                                <SectionHeader $type={atomic.evidenceClass} variant='caption'>
                                    UNVERIFIED REFERENCES:
                                </SectionHeader>
                                <InfoTooltip title='Due to a technical issue reference verification is temporarily unavaliable. Our support team is on it! You can continue using unverified references for now. Reference verification will be automatically re-enabled as soon as possible.'>
                                    <InfoIcon />
                                </InfoTooltip>
                            </TitleRowContainer>
                            <Typography variant='caption'>Reference verification is temporarily unavaliable.</Typography>
                            <ReferenceList references={atomic.references} />
                        </InnerContainer>
                    </StyledReferenceCard>
                </Popover>
            )
        case 'BASIC':
            return (
                <Popover {...popoverProps}>
                    <StyledReferenceCard $type={atomic.evidenceClass}>
                        <StyledCloseButton onClick={handleClose} aria-label='close card'>
                            <CrossIcon color={palette.text.secondary} height={15} width={15} />
                        </StyledCloseButton>
                        <InnerContainer>
                            <TitleRowContainer>
                                <SectionHeader $type={atomic.evidenceClass} variant='caption'>
                                    REFERENCES:
                                </SectionHeader>
                                <InfoTooltipWithHyperlink
                                    title={
                                        <span>
                                            We recommend reviewing these references for accuracy, as they have not been double-checked by SpringLaw Verify.
                                            SpringLaw Verify will be available soon.{' '}
                                            <a
                                                href='https://www.loom.com/share/f1103529a4564cbc9ff52347be5633f6?sid=1a4f846d-ce3e-4e1c-b8c9-7212157e28ce'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Click here to learn more.
                                            </a>
                                        </span>
                                    }
                                >
                                    <span>
                                        <InfoIcon />
                                    </span>
                                </InfoTooltipWithHyperlink>
                            </TitleRowContainer>
                            <ReferenceList references={atomic.references} />
                        </InnerContainer>
                    </StyledReferenceCard>
                </Popover>
            )
    }
}

export default ReferenceCard
