import Modal, { ModalProps } from '../common/Modal'
import PromptLibraryWrapper from './PromptLibraryWrapper'
import SimplePromptDetail from './SimplePromptDetail'
import TemplatePromptDetail from './TemplatePromptDetail'
import Loading from '../common/Loading'
import { usePromptContext } from '../../context/PromptContext'
import { isTemplatePrompt } from './promptUtils'
import CreatePromptLibrary from './CreatePromptLibrary'
import EditPromptLibrary from './EditPromptLibrary'
import CreatePrompt from './CreatePrompt'
import EditPrompt from './EditPrompt'
import { styled } from '@mui/material'
import PromptLibrarySidebar from './PromptLibrarySidebar'

export const drawerWidth = 220

const StyledModal = styled(Modal)(({ theme }) => ({
    width: `calc(75dvw - ${drawerWidth}px)`,
    paddingLeft: `calc(${drawerWidth}px + 40px)`,
    height: '80dvh',

    [theme.breakpoints.up('lg')]: {
        width: `calc(80dvw - ${drawerWidth}px)`,
        height: '80dvh',
    },
}))

interface PromptLibraryModalProps extends ModalProps {
    onSetInput: (input: string) => void
}

const PromptLibraryModal = ({ show, onClose, onSetInput }: PromptLibraryModalProps) => {
    const { loading, prompt, selectedLibrary, view, onSelectPrompt, onEditLibrary, onEditPrompt } = usePromptContext()

    const handleUsePrompt = (promptMessage: string) => {
        onSetInput(promptMessage)
        onClose()
    }

    const closePromptModal = () => {
        onSelectPrompt(null)
        onEditLibrary(false)
        onEditPrompt(false)
        onClose()
    }

    const getHeading = () => {
        switch (view) {
            case 'list':
                return undefined
            case 'prompt details':
                return 'Prompt Library'
            case 'new prompt':
                return 'Create New Prompt'
            case 'edit prompt':
                return 'Edit Prompt'
            case 'new library':
                return 'Create New Library'
            case 'edit library':
                return 'Edit Library'
        }
    }

    const renderContent = () => {
        if (loading) {
            return <Loading fullSize={true} primaryColor={true} />
        }

        switch (view) {
            case 'list':
                return <PromptLibraryWrapper />
            case 'new library':
                return <CreatePromptLibrary />
            case 'edit library':
                if (!selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} primaryColor={true} />
                }
                return <EditPromptLibrary library={selectedLibrary} />
            case 'new prompt':
                if (!selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} primaryColor={true} />
                }
                return <CreatePrompt library={selectedLibrary} />
            case 'edit prompt':
                if (!prompt || !selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} primaryColor={true} />
                }
                return <EditPrompt library={selectedLibrary} prompt={prompt} />
            case 'prompt details':
                if (!prompt) {
                    return <Loading fullSize={true} primaryColor={true} />
                }
                return isTemplatePrompt(prompt) ? <TemplatePromptDetail onUsePrompt={handleUsePrompt} /> : <SimplePromptDetail onUsePrompt={handleUsePrompt} />
        }
    }

    return (
        <StyledModal show={show} onClose={closePromptModal} heading={getHeading()} alignTitleCenter={false}>
            <PromptLibrarySidebar />
            {renderContent()}
        </StyledModal>
    )
}

export default PromptLibraryModal
