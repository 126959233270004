import { CommonColors, createTheme, Interpolation, PaletteColorOptions, Theme, TypeAction, TypeBackground } from '@mui/material/styles'
import { breakpoints } from './styles'
import { Company } from '../../types'
import { PaletteMode, keyframes } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CSSProperties, TypographyStyleOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
    interface Theme {
        company?: Company
        logo?: {
            width: number
            height: number
        }
    }
    interface ThemeOptions {
        company?: Company
        logo?: {
            width: number
            height: number
        }
    }
    interface TypographyVariants {
        chatMessage: CSSProperties
    }
    interface TypographyVariantsOptions {
        chatMessage?: TypographyStyleOptions
    }
}

interface BasePalette {
    mode: PaletteMode
    common: Partial<CommonColors>
    secondary: PaletteColorOptions
    background: Partial<TypeBackground>
    action: Partial<TypeAction>
    divider: string
    error: PaletteColorOptions
    warning: PaletteColorOptions
    success: PaletteColorOptions
    info: PaletteColorOptions
}

const ThemeWithBreakpoints = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: breakpoints.mobile,
            md: breakpoints.half,
            lg: breakpoints.laptop,
            xl: 1536,
        },
    },
})

const ThemeWithTypography = createTheme(ThemeWithBreakpoints, {
    typography: {
        h1: {
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Manrope',
            margin: 'revert',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '20px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '18px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '16px',
            },
        },
        h2: {
            fontSize: '18px',
            fontWeight: 600,
            fontFamily: 'Manrope',
            margin: 'revert',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '18px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '18px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '15px',
            },
        },
        h3: {
            fontSize: '16px',
            fontWeight: 600,
            fontFamily: 'Manrope',
            margin: 'revert',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '16px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },

        h4: {
            margin: 0,
            fontSize: '16px',
            fontWeight: 400,
            fontStyle: 'italic',
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },

        h5: {
            margin: 0,
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Nunito Sans',
            fontStyle: 'italic',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        h6: {
            margin: 0,
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'Manrope',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
        body1: {
            fontSize: '14px',
            margin: 0,
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
        body2: {
            fontSize: '13px',
            margin: 0,
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '13px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '13px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
        subtitle1: {
            fontSize: '14px',
            margin: 0,
            fontWeight: 400,
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '15px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        subtitle2: {
            fontSize: '12px',
            margin: 0,
            fontWeight: 400,
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '12px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '12px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
        button: {
            fontSize: '16px',
        },
        caption: {
            fontSize: '12px',
            margin: 0,
            fontFamily: 'Nunito Sans',
            letterSpacing: 'normal',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '12px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '12px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
        chatMessage: {
            fontSize: '16px',
            margin: 0,
            fontFamily: 'Nunito Sans',
            [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                fontSize: '16px',
            },
            [ThemeWithBreakpoints.breakpoints.down('md')]: {
                fontSize: '14px',
            },
            [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
    },
})

const ThemeWithComponents = createTheme(ThemeWithTypography, {
    components: {
        MuiFormControlLabel: {
            defaultProps: {
                hidden: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: false,
            },
            styleOverrides: {
                root: {
                    position: 'relative',
                    height: '40px',
                    legend: { display: 'none' },
                    '&.MuiInputBase-multiline': {
                        height: 'unset',

                        '*::-webkit-scrollbar-track': {
                            marginBottom: 10,
                        },
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: (props: { theme: Theme }) => ({
                    color: props.theme.palette.text.primary,
                }),
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: false,
            },
            styleOverrides: {
                root: {
                    ...ThemeWithTypography.typography.caption,
                    marginBottom: 8,
                    transform: 'none',
                    position: 'relative',
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: ExpandMoreIcon,
            },
            styleOverrides: {
                root: {
                    '&:focus .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        height: '100%',
                        top: 0,
                    },
                },
                select: {
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 16px',
                    minWidth: 100,
                    top: 1,
                },
                icon: {
                    color: 'inherit',
                    width: 24,
                    height: '100%',
                    top: 1,
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                variant: 'scrollable',
                scrollButtons: 'none',
            },
            styleOverrides: {
                root: {
                    minHeight: 'unset',
                    margin: '0 0 10px',
                },

                flexContainer: (props: { theme: Theme }) => {
                    return {
                        borderBottom: `2px solid ${props.theme.palette.divider}`,
                        height: 'calc(90% + 1px)',
                    }
                },
                indicator: {
                    bottom: '0px',
                    zIndex: 10,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    flexDirection: 'row',
                    textTransform: 'none',
                    borderBottom: 'none',
                    padding: '6px',
                    minHeight: 36,
                    minWidth: 40,
                    justifyContent: 'flex-start',
                    ...ThemeWithTypography.typography.h6,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: (props: { theme: Theme }) => {
                    return {
                        backgroundImage: 'none',
                        backgroundColor: props.theme.palette.background.default,
                        border: `1px solid ${props.theme.palette.divider}`,
                        color: props.theme.palette.text.primary,
                        boxShadow:
                            props.theme.palette.mode === 'dark'
                                ? '0px 4px 12px rgba(0, 0, 0, 0.3), 0px 2px 4px rgba(0, 0, 0, 0.15)'
                                : '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
                        maxWidth: 400,
                    }
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: (props: { theme: Theme }) => {
                    return {
                        '&.Mui-selected': {
                            backgroundColor: props.theme.palette.background.default,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: props.theme.palette.action.hover,
                        },
                        '&:hover': {
                            backgroundColor: props.theme.palette.action.hover,
                        },
                    }
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: 'none',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                onWheel: (e: WheelEvent) => {
                    e.target instanceof HTMLInputElement && e.target.type === 'number' && e.target.blur()
                },
            },
            styleOverrides: {
                root: {
                    '.MuiInputBase-inputMultiline': {
                        resize: 'vertical',
                    },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    'input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiInputBase-root': {
                        paddingTop: 0,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: (props: { theme: Theme }) => ({
                    cursor: 'pointer',
                    color: props.theme.palette.text.primary,
                    fontSize: '14px',
                    margin: 0,
                    fontFamily: 'Nunito Sans',
                    [ThemeWithBreakpoints.breakpoints.down('lg')]: {
                        fontSize: '14px',
                    },
                    [ThemeWithBreakpoints.breakpoints.down('md')]: {
                        fontSize: '14px',
                    },
                    [ThemeWithBreakpoints.breakpoints.down('sm')]: {
                        fontSize: '12px',
                    },
                }),
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#4C4954',
                    opacity: 0.95,
                    ...ThemeWithTypography.typography.body2,
                },
                arrow: {
                    color: '#4C4954',
                },
            },
        },
    },
})

const baseTheme = createTheme(ThemeWithComponents, {
    shape: {
        borderRadius: 8,
    },
})

//Shared palette for all light themes
const lightColors: BasePalette = {
    mode: 'light',
    common: {
        white: '#ffffff',
        black: '#000000',
    },
    secondary: {
        main: '#E6E6E6',
        light: '#FAFAFA',
        dark: '#DBDDDF',
    },
    background: {
        default: '#FFFFFF',
        paper: '#F4F5F6',
    },
    action: {
        disabled: '#B7BCBF',
        disabledBackground: '#F0F0F0',
    },
    divider: '#DBDDDF',
    error: {
        main: '#FFADAD',
        light: '#FFCCCC',
        dark: '#6e2527',
    },
    warning: {
        main: '#FFEE93',
        light: '#FFF2B0',
        dark: '#E0BF10',
    },
    success: {
        main: '#9ff2b4',
        light: '#BAF5C9',
        dark: '#186B36',
    },
    info: {
        main: '#BBC6DC',
        light: '#B9C9EC',
        dark: '#002C8E',
    },
}

//Shared palette for all dark themes
const darkColors: BasePalette = {
    mode: 'dark',
    common: {
        white: '#ffffff',
        black: '#000000',
    },
    secondary: {
        main: '#2A2A3F',
        light: '#18181B',
        dark: '#2F2F34',
    },
    background: {
        default: '#1C1C1F',
        paper: '#212125',
    },
    action: {
        disabled: '#676772',
        disabledBackground: '#343438',
    },
    divider: '#2F2F34',
    error: {
        main: '#CB3E3E',
        light: '#FFCCCC',
        dark: '#7A1F21',
    },
    warning: {
        main: '#E0BF10',
        light: '#FFF6C7',
        dark: '#706009',
    },
    success: {
        main: '#127C52',
        light: '#C0ECD0',
        dark: '#1B5237',
    },
    info: {
        main: '#5B6A87',
        light: '#B9C9EC',
        dark: '#6264FF',
    },
}

type CompanyTheme = Pick<Theme, 'company' | 'logo'>

const springbokTheme: CompanyTheme = {
    company: 'Springbok',
    logo: {
        width: 260,
        height: 150,
    },
}

export const springbokLightTheme = createTheme(baseTheme, {
    ...springbokTheme,
    palette: {
        primary: {
            main: '#29195E',
            contrastText: '#F6F6F6',
            dark: '#130934',
        },
        text: {
            primary: '#1D182E',
            secondary: '#75727E',
            disabled: '#B7BCBF',
        },
        ...lightColors,
    },
})

export const springbokDarkTheme = createTheme(baseTheme, {
    ...springbokTheme,
    palette: {
        primary: {
            main: '#5242B6',
            contrastText: '#F6F6F6',
            dark: '#312194',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#969696',
            disabled: '#676772',
        },
        ...darkColors,
    },
})

const charlesRussellSpeechlysTheme: CompanyTheme = {
    company: 'CharlesRussellSpeechlys',
    logo: {
        width: 200,
        height: 200,
    },
}

export const charlesRussellSpeechlysLightTheme = createTheme(baseTheme, {
    ...charlesRussellSpeechlysTheme,
    palette: {
        primary: {
            main: '#28A944',
            contrastText: '#FFFFFF',
            dark: '#178128',
        },
        text: {
            primary: '#000000',
            secondary: '#75727E',
            disabled: '#B7BCBF',
        },
        ...lightColors,
    },
})

export const charlesRussellSpeechlysDarkTheme = createTheme(baseTheme, {
    ...charlesRussellSpeechlysTheme,
    palette: {
        primary: {
            main: '#30B576',
            contrastText: '#F6F6F6',
            dark: '#139B5B',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#969696',
            disabled: '#676772',
        },
        ...darkColors,
    },
})

const charlesRussellSpeechlysUATTheme: CompanyTheme = {
    company: 'CharlesRussellSpeechlysUAT',
    logo: {
        width: 200,
        height: 200,
    },
}

export const charlesRussellSpeechlysUATLightTheme = createTheme(baseTheme, {
    ...charlesRussellSpeechlysUATTheme,
    palette: {
        primary: {
            main: '#D04B39',
            contrastText: '#FFFFFF',
            dark: '#AF3B2C',
        },
        text: {
            primary: '#000000',
            secondary: '#75727E',
            disabled: '#B7BCBF',
        },
        ...lightColors,
    },
})

export const charlesRussellSpeechlysUATDarkTheme = createTheme(baseTheme, {
    ...charlesRussellSpeechlysUATTheme,
    palette: {
        primary: {
            main: '#E95260',
            contrastText: '#F6F6F6',
            dark: '#BF3844',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#969696',
            disabled: '#676772',
        },
        ...darkColors,
    },
})

const accorTheme: CompanyTheme = {
    company: 'Accor',
    logo: {
        width: 250,
        height: 150,
    },
}

export const accorLightTheme = createTheme(baseTheme, {
    ...accorTheme,
    palette: {
        primary: {
            main: '#0098AD',
            contrastText: '#FFFFFF',
            dark: '#01707e',
        },
        text: {
            primary: '#000000',
            secondary: '#75727E',
            disabled: '#B7BCBF',
        },
        ...lightColors,
    },
})

export const accorDarkTheme = createTheme(baseTheme, {
    ...accorTheme,
    palette: {
        primary: {
            main: '#008A9D',
            contrastText: '#FFFFFF',
            dark: '#006B79',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#969696',
            disabled: '#676772',
        },
        ...darkColors,
    },
})

//Global
export const globalStyles: Interpolation<Theme> = (theme: Theme) => ({
    //Scrollbars
    ':root': {
        scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
    },
    '*::-webkit-scrollbar-track': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#e9e9e9',
    },
    '*::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'dark' ? '#b9b9b9' : '#BFBFBF',
    },
    '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#DCDCDC',
    },
    //Markdown
    '.chatMessage-markdown': {
        ...theme.typography.chatMessage,
    },
    '.body1-markdown': {
        ...theme.typography.body1,
    },
    //Global
    '.visible-on-hover-parent': {
        ':hover': {
            '.visible-on-hover': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    '.visible-on-hover': {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.2s ease, visibility 0.2s ease',
    },
})

//ANIMATIONS
export const rotate180degAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`
