import { styled } from '@mui/material'
import UnstyledButton from './UnstyledButton'

const TextButton = styled(UnstyledButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    fontSize: '14px',
}))

export default TextButton
