import { styled } from '@mui/material'
import { environmentVariables } from '../../env'
import { allowedFileExtensions } from '../../utils/documents'
import { Typography } from '@mui/material'
import { Id, toast } from 'react-toastify'
import { supportMessage } from '../../utils/userMessages'

const StyledList = styled('ul')({
    margin: '0',
})

const SequentialList = styled('ul')({
    margin: '0 0 15px 0',
})

interface FileListProps {
    files: File[]
}

export const LargeFileList = ({ files }: FileListProps) => (
    <>
        <Typography>The following documents exceed the size limit of {environmentVariables.documentSizeByteLimit / 1000}KB</Typography>
        <StyledList>
            {files.map(f => (
                <li key={f.name}>{f.name}</li>
            ))}
        </StyledList>
    </>
)

export const InvalidFormatList = ({ files }: FileListProps) => (
    <>
        <Typography>The following documents are in an unsupported format:</Typography>
        <SequentialList>
            {files.map(f => (
                <li key={f.name}>{f.name}</li>
            ))}
        </SequentialList>
        <Typography>Supported formats are: </Typography>
        <StyledList>
            {allowedFileExtensions.map(e => (
                <li key={e}>{`.${e}`}</li>
            ))}
        </StyledList>
    </>
)

export const EmptyFileList = ({ files }: FileListProps) => (
    <>
        <Typography>The following documents are empty so have been removed:</Typography>
        <StyledList>
            {files.map(f => (
                <li key={f.name}>{f.name}</li>
            ))}
        </StyledList>
    </>
)

export const FailedUploadList = ({ files }: FileListProps) => (
    <>
        <Typography>The following documents failed to upload:</Typography>
        <StyledList>
            {files.map(f => (
                <li key={f.name}>{f.name}</li>
            ))}
        </StyledList>
        <Typography>{supportMessage}</Typography>
    </>
)

export const toastFailedUploadList = (toastId: Id, props: FileListProps) =>
    toast.update(toastId, { type: 'error', render: <FailedUploadList {...props} />, isLoading: false, autoClose: 5000 })
