import UnstyledButton from './common/UnstyledButton'
import RetryIcon from '../icons/RetryIcon'
import { useMessageContext } from '../context/MessageContext'
import { useChatContext } from '../context/ChatContext'
import { Message, isDocumentUserMessage } from '../types'
import { useUserContext } from '../context/UserContext'
import InfoTooltip from './common/tooltips/InfoTooltip'
import { forwardPropsWithTransient } from '../utils/muiUtils'
import { styled } from '@mui/material'

const Container = styled('div', {
    shouldForwardProp: forwardPropsWithTransient,
})<{ $fromUser: boolean }>(({ $fromUser }) => ({
    width: '100%',
    height: '28px',
    textAlign: $fromUser ? 'end' : 'start',
    paddingTop: '5px',
}))

const Button = styled(UnstyledButton)({
    '&:disabled': {
        opacity: 0.3,
    },
})

interface MessageControlsProps {
    className?: string
    message: Message
    isLatestMessage: boolean
}

const MessageControls = ({ className, message, isLatestMessage }: MessageControlsProps) => {
    const { hasRemainingQuota } = useUserContext()
    const { currentChat } = useChatContext()
    const { retryLastUserMessage } = useMessageContext()

    const handleRetryLastMessage = () => {
        if (currentChat) {
            retryLastUserMessage(currentChat.id)()
        }
    }

    const { fromUser } = message
    const canRetryLastUserMessage = fromUser && isLatestMessage && currentChat?.status === 'active' && !isDocumentUserMessage(message) && hasRemainingQuota

    return (
        <Container className={className} $fromUser={fromUser}>
            {canRetryLastUserMessage && (
                <>
                    <InfoTooltip title='Retry this message' role='tooltip'>
                        <Button onClick={handleRetryLastMessage} disabled={!canRetryLastUserMessage}>
                            <RetryIcon />
                        </Button>
                    </InfoTooltip>
                </>
            )}
        </Container>
    )
}

export default MessageControls
