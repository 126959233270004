import { styled } from '@mui/material'
import { areaLayoutStyles } from '../common/styles'
import ReportControls from './ReportControls'
import useIsMobile from '../../hooks/useIsMobile'
import { WithReportContext } from '../../context/ReportContext'
import UnavailableInMobile from '../common/UnavailableInMobile'
import ChatHeader from '../ChatHeader'
import WithValidation from '../WithValidation'
import ReportContent from './ReportContent'
import { ReportChat } from '../../types'

const StyledHeader = styled(ChatHeader)(({ theme }) => ({
    ...areaLayoutStyles(theme).header,
}))

const Content = styled(ReportContent)(({ theme }) => ({
    ...areaLayoutStyles(theme).content,
}))

const Footer = styled(ReportControls)(({ theme }) => ({
    ...areaLayoutStyles(theme).footer,
}))

interface ReportLayoutProps {
    currentChat: ReportChat
}

const ReportLayout = ({ currentChat }: ReportLayoutProps) => {
    const isMobile = useIsMobile()

    if (isMobile) {
        return <UnavailableInMobile featureName='Report Generators' />
    }

    return (
        <WithReportContext currentChat={currentChat}>
            <StyledHeader />
            <Content />
            <WithValidation>
                <Footer />
            </WithValidation>
        </WithReportContext>
    )
}

export default ReportLayout
