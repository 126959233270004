import { useCallback, useState } from 'react'
import { FileWithMetadata, UUID } from '../types'
import {
    sessionDocumentFinaliseEndpoint,
    sessionDocumentUploadUrlsEndpoint,
    knowledgeBaseDocumentFinaliseEndpoint,
    knowledgeBaseDocumentUploadUrlsEndpoint,
} from '../endpoints'
import { captureMessage } from '@sentry/react'
import { useUpload } from './useUpload'
import { toast } from 'react-toastify'
import { toastFailedUploadList } from '../components/notifications/FileListNotifications'

export interface GroupedUploadResult {
    uploadedIds: UUID[]
    rejectedIds: UUID[]
}

export const getEmptyGroupedUploadResult = (): GroupedUploadResult => ({
    uploadedIds: [],
    rejectedIds: [],
})

const getFailedFiles = (files: FileWithMetadata[], failureIds: UUID[]) =>
    files.reduce((acc: File[], f) => {
        if (failureIds.includes(f.id)) {
            acc.push(f.file)
        }
        return acc
    }, [])

const useDocumentUpload = (
    uploadUrlsEndpoint: string | null,
    getFinaliseEndpoint: ((documentId: UUID) => string) | null
): [makeRequest: (files: FileWithMetadata[]) => Promise<GroupedUploadResult>, loading: boolean] => {
    const [loading, setLoading] = useState(false)

    const [uploadFile] = useUpload(uploadUrlsEndpoint, getFinaliseEndpoint)

    const makeRequest = useCallback(
        async (files: FileWithMetadata[]) => {
            setLoading(true)
            const toastId = toast.loading('Uploading document(s)...')

            const promises = files.map(f => uploadFile(f))

            const promiseResults = await Promise.allSettled(promises)
            setLoading(false)

            const groupedResult = promiseResults.reduce((groupedResult: GroupedUploadResult, promiseResult) => {
                if (promiseResult.status === 'fulfilled') {
                    const { documentId, uploadSuccessful } = promiseResult.value
                    uploadSuccessful ? groupedResult.uploadedIds.push(documentId) : groupedResult.rejectedIds.push(documentId)
                } else {
                    captureMessage(`Document upload promise rejected instead of returning failure result. Reason: ${promiseResult.reason}`)
                }
                return groupedResult
            }, getEmptyGroupedUploadResult())

            if (groupedResult.rejectedIds.length) {
                toastFailedUploadList(toastId, { files: getFailedFiles(files, groupedResult.rejectedIds) })
            } else {
                toast.dismiss(toastId)
            }

            return groupedResult
        },
        [uploadFile]
    )

    return [makeRequest, loading]
}

export const useChatDocumentUpload = (chatId?: UUID): [makeRequest: (files: FileWithMetadata[]) => Promise<GroupedUploadResult>, loading: boolean] =>
    useDocumentUpload(chatId ? sessionDocumentUploadUrlsEndpoint(chatId) : null, chatId ? sessionDocumentFinaliseEndpoint(chatId) : null)

export const useKnowledgeBaseDocumentUpload = (botId?: UUID): [makeRequest: (files: FileWithMetadata[]) => Promise<GroupedUploadResult>, loading: boolean] =>
    useDocumentUpload(botId ? knowledgeBaseDocumentUploadUrlsEndpoint(botId) : null, botId ? knowledgeBaseDocumentFinaliseEndpoint(botId) : null)
