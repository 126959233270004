import { addBreadcrumb, captureException, setContext } from '@sentry/react'
import { cancellableSleep, exponentialIncrement } from './timeout'
import { CloudProvider, DocumentPart, UploadPart } from '../apiTypes'
import { AppendBlobClient, newPipeline } from '@azure/storage-blob'

interface DocumentData {
    document: File
    uploadId: string
    key: string
    cloudProvider: CloudProvider
}

export const chunkSize = 1024 * 1024 * 5
export const threadsLimit = 5
const chunkRetryLimit = 3
const getExponentialBackoff = exponentialIncrement(500, 5000)

class UploadClient {
    #abortController = new AbortController()
    #documentData: DocumentData
    #partsToUpload: UploadPart[]
    #activeThreads: Promise<void>[] = []
    #activeConnections: Record<number, Promise<Response>> = {}
    #uploadedParts: DocumentPart[] = []

    constructor(document: File, uploadId: string, documentKey: string, parts: UploadPart[], cloudProvider: CloudProvider) {
        this.#documentData = { document, uploadId, key: documentKey, cloudProvider }
        this.#partsToUpload = parts
        setContext('document', { name: document.name, size: document.size, cloudProvider })
    }

    async uploadInParts() {
        if (this.#documentData.cloudProvider === 'azure') {
            return await this.#azureUploadInParts()
        }

        // AWS
        try {
            await this.#sendNext()
            await Promise.allSettled(this.#activeThreads)

            return this.#uploadedParts.length === 0 ? null : this.#uploadedParts
        } catch (error) {
            captureException(error)
            this.#abortController.abort()
            return null
        }
    }

    async #azureUploadInParts() {
        try {
            const blobClient = new AppendBlobClient(this.#partsToUpload[0].presignedUrl, newPipeline())

            for (const part of this.#partsToUpload) {
                const sentSize = (part.partNumber - 1) * chunkSize
                const chunk = this.#documentData.document.slice(sentSize, sentSize + chunkSize)

                const response = await blobClient.appendBlock(chunk, chunk.size)
                if (response.errorCode) {
                    throw new Error(`Failed to append block with error code: ${response.errorCode}`)
                }

                this.#uploadedParts.push({ partNumber: part.partNumber, eTag: response.etag ?? '' })
            }

            return this.#uploadedParts
        } catch (error) {
            captureException(error)
            this.#abortController.abort()
            return null
        }
    }

    async #sendNext(retry = 0) {
        const numberOfActiveConnections = Object.keys(this.#activeConnections).length

        if (numberOfActiveConnections >= threadsLimit || this.#abortController.signal.aborted) {
            return
        }

        if (!this.#partsToUpload.length) {
            return
        }

        const part = this.#partsToUpload.pop()
        if (this.#documentData && part) {
            const sentSize = (part.partNumber - 1) * chunkSize
            const chunk = this.#documentData.document.slice(sentSize, sentSize + chunkSize)

            try {
                await this.#uploadChunk(part, chunk, this.#sendNext.bind(this))
                await this.#sendNext()
            } catch (error) {
                if (this.#abortController.signal.aborted) {
                    return
                }

                delete this.#activeConnections[part.partNumber]
                if (retry <= chunkRetryLimit) {
                    ++retry

                    try {
                        await cancellableSleep(this.#abortController.signal)(getExponentialBackoff(retry))
                    } catch (error) {
                        if (this.#abortController.signal.aborted) {
                            return
                        }
                        throw error
                    }

                    this.#partsToUpload.push(part)
                    await this.#sendNext(retry)
                } else {
                    addBreadcrumb({ message: `Part #${part.partNumber}`, level: 'debug' })
                    throw error
                }
            }
        }
    }

    async #uploadChunk(part: UploadPart, chunk: Blob, onStart: () => Promise<void>) {
        const responsePromise = fetch(part.presignedUrl, { method: 'PUT', body: chunk, signal: this.#abortController.signal })
        this.#activeConnections[part.partNumber] = responsePromise

        const thread = onStart()
        this.#activeThreads.push(thread)

        const response = await responsePromise

        if (response.ok) {
            const eTag = response.headers.get('ETag')

            if (eTag) {
                this.#uploadedParts.push({ partNumber: part.partNumber, eTag: eTag.replaceAll('"', '') })

                delete this.#activeConnections[part.partNumber]
            } else {
                throw new Error(`Missing eTag for part #${part.partNumber}`)
            }
        } else {
            throw new Error(await this.#getFailureReason(response))
        }
    }

    async #getFailureReason(response: Response) {
        if (this.#documentData.cloudProvider === 'aws') {
            try {
                const details = new DOMParser().parseFromString(await response.text(), 'text/xml').getElementsByTagName('Message')[0].textContent ?? undefined
                return `${response.statusText}\n${details}`
            } catch {
                return response.statusText
            }
        }

        return response.statusText
    }
}

export default UploadClient
