import { styled, Typography } from '@mui/material'
import { Reference } from '../../../types'
import ReferenceLinkDownload from './ReferenceLinkDownload'
import { useReferencesContext } from '../../../context/ReferencesContext'

const TextRowWithImage = styled(Typography)(() => ({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',

    svg: {
        paddingBottom: '2px',
    },
}))

const DocReferenceContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

interface ReferenceListProps {
    references: Reference[]
}

const ReferenceList = ({ references }: ReferenceListProps) => {
    const { sources } = useReferencesContext()

    return references
        .sort((a, b) => b.score - a.score)
        .map(reference => {
            const source = sources.find(s => s.id === reference.sourceId)

            return (
                source && (
                    <DocReferenceContainer key={source.id}>
                        <TextRowWithImage variant='caption'>
                            <ReferenceLinkDownload documentId={source.documentId} documentName={source.documentName} />
                        </TextRowWithImage>
                        <Typography aria-label='document reference text'>"{source.text}"</Typography>
                    </DocReferenceContainer>
                )
            )
        })
}

export default ReferenceList
