import NewBotModal from '../components/bot-config/NewBotModal'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { Bot, doNothing } from '../types'
import { useUserContext } from './UserContext'
import BotConfigModal from '../components/bot-config/BotConfigModal'
import BotDetailsModal from '../components/bot-details/BotDetailsModal'

interface BotState {
    editBotConfig: (bot: Bot | 'new' | null) => void
    viewBotDetails: (bot: Bot | null) => void
}

export const defaultState: BotState = {
    editBotConfig: doNothing,
    viewBotDetails: doNothing,
}

export const BotContext = createContext<BotState>(defaultState)

const WithBotContext = ({ children }: PropsWithChildren) => {
    const { isMaintainer } = useUserContext()
    const [editBot, setEditBot] = useState<Bot | 'new' | null>(null)
    const [viewBot, setViewBot] = useState<Bot | null>(null)

    return (
        <BotContext.Provider value={{ editBotConfig: setEditBot, viewBotDetails: setViewBot }}>
            {children}

            {isMaintainer &&
                editBot &&
                (editBot === 'new' ? (
                    <NewBotModal show onClose={() => setEditBot(null)} />
                ) : (
                    <BotConfigModal show onClose={() => setEditBot(null)} bot={editBot} />
                ))}
            {!editBot && viewBot && <BotDetailsModal show onClose={() => setViewBot(null)} bot={viewBot} />}
        </BotContext.Provider>
    )
}

const useBotContext = () => useContext(BotContext)

export { WithBotContext, useBotContext }
