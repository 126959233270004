import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { useGet } from '../hooks/useGet'
import { useChatContext } from './ChatContext'
import { getSourcesEndpoint } from '../endpoints'
import { useMessageContext } from './MessageContext'
import { Source, UUID } from '../types'
import { SourceResponse } from '../apiTypes'
import { canMergeWithoutIdConflicts } from '../utils/objectUtils'

interface ReferencesState {
    sources: Source[]
}

const defaultState: ReferencesState = {
    sources: [],
}

export const ReferencesContext = createContext<ReferencesState>(defaultState)

interface ReferenceContextProps extends PropsWithChildren {
    chatId: UUID
}

const WithReferencesContextPage = ({ chatId, children }: ReferenceContextProps) => {
    const { incomingSourcesUpdate } = useMessageContext()

    const [sources, setSources] = useState<Source[]>(defaultState.sources)

    const [sourceResponse, loading] = useGet<SourceResponse[]>(getSourcesEndpoint(chatId))

    const [prevLoading, setPrevLoading] = useState(loading)
    if (loading !== prevLoading) {
        setPrevLoading(loading)

        if (!loading && sourceResponse) {
            setSources(sourceResponse)
        }
    }

    if (incomingSourcesUpdate && canMergeWithoutIdConflicts(sources, incomingSourcesUpdate)) {
        setSources(sources.concat(incomingSourcesUpdate))
    }

    return (
        <ReferencesContext.Provider
            value={{
                sources,
            }}
        >
            {children}
        </ReferencesContext.Provider>
    )
}

const WithReferencesContext = (props: PropsWithChildren) => {
    const { currentChat } = useChatContext()

    if (!currentChat) {
        return props.children
    }

    return <WithReferencesContextPage key={currentChat.id} chatId={currentChat.id} {...props} />
}

const useReferencesContext = () => useContext(ReferencesContext)

export { WithReferencesContext, useReferencesContext }
