import { styled } from '@mui/material'

const UnstyledButton = styled('button')({
    all: 'unset',
    cursor: 'pointer',
    userSelect: 'none',
    boxSizing: 'border-box',
})

export default UnstyledButton
