import { styled } from '@mui/material'
import { inputStyles } from './Input'

const TextArea = styled('textarea')(({ theme }) => ({
    ...inputStyles(theme),
    outline: 'none',
    overflowWrap: 'anywhere',

    '&:focus-visible': {
        outline: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
    },
}))

export default TextArea
