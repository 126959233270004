import { PromptLibrary, PromptLibraryPayload } from './types'
import { usePost } from '../../hooks/usePost'
import { usePromptContext } from '../../context/PromptContext'
import { librariesEndpoint, libraryEndpoint } from '../../endpoints'
import LibraryForm from './LibraryForm'
import { useDelete } from '../../hooks/useDelete'
import { doNothing, UUID } from '../../types'
import { canDeleteLibrary } from './permissions'

interface EditPromptLibraryProps {
    library: PromptLibrary
}

const EditPromptLibrary = ({ library }: EditPromptLibraryProps) => {
    const { refreshLibraries, onEditLibrary, onLibraryChange, promptLibraries } = usePromptContext()

    const [saveLibrary, loading] = usePost<PromptLibraryPayload, boolean>(libraryEndpoint(library.id), { method: 'PUT' })
    const [deleteLibrary] = useDelete(librariesEndpoint)

    const handleSubmit = async (payload: PromptLibraryPayload) => {
        const success = await saveLibrary(payload)

        if (success) {
            refreshLibraries()
            onEditLibrary(false)
        }
    }

    const handleDelete = async (libraryId: UUID) => {
        const success = await deleteLibrary(libraryId)

        if (success) {
            refreshLibraries()
            onLibraryChange(promptLibraries?.length ? promptLibraries[0].id : null)
            onEditLibrary(false)
        }
    }

    return (
        <LibraryForm
            library={library}
            loading={loading}
            onBack={() => onEditLibrary(false)}
            onSubmit={handleSubmit}
            onDelete={canDeleteLibrary(library.userRole) ? handleDelete : doNothing}
        />
    )
}

export default EditPromptLibrary
