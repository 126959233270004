import { captureMessage } from '@sentry/react'
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Id, toast } from 'react-toastify'
import useWebSocket from 'react-use-websocket'
import { MessagePayload, isIncomingSocketMessage } from '../apiTypes'
import { CellUpdate } from '../components/doc-skimmer/utils/types'
import ChatNotification from '../components/notifications/ChatNotification'
import { socketEndpoint } from '../endpoints'
import { useAcquireUserCredentials } from '../hooks/useAcquireUserCredentials'
import { ChatProgressMessage, Chunk, DocumentUpdate, Message, Source, UUID, UserMessage, doNothing, doNothingEventually } from '../types'
import { generateId } from '../utils/objectUtils'
import { exponentialIncrement } from '../utils/timeout'
import { supportMessage } from '../utils/userMessages'
import { useChatContext } from './ChatContext'
import { useFileContext } from './FileContext'
import { useUserContext } from './UserContext'

interface MessageState {
    refreshRequestChatId: UUID | null
    incomingMessage: Message | null
    incomingDocumentUpdate: DocumentUpdate | null
    incomingProgressMessage: ChatProgressMessage
    incomingChunk: Chunk | null
    incomingCellUpdate: CellUpdate | null
    incomingSourcesUpdate: Source[] | null
    outgoingMessage: UserMessage | null
    sendMessage: (chatId: UUID) => (text: string) => void
    sendDocument: (chatId: UUID) => (text?: string) => Promise<void>
    retryLastUserMessage: (chatId: UUID) => () => void
}

export const defaultState: MessageState = {
    refreshRequestChatId: null,
    incomingMessage: null,
    incomingProgressMessage: null,
    incomingDocumentUpdate: null,
    incomingChunk: null,
    incomingCellUpdate: null,
    incomingSourcesUpdate: null,
    outgoingMessage: null,
    sendMessage: () => doNothing,
    sendDocument: () => doNothingEventually,
    retryLastUserMessage: () => doNothing,
}
export const MessageContext = createContext(defaultState)

const getExponentialBackoff = exponentialIncrement(5000, 30000)

const WithMessageContext = ({ children }: PropsWithChildren) => {
    const { refreshUser } = useUserContext()
    const { currentChat, updateChat, lockCurrentChat, unlockCurrentChat, blockChatSwapping, unblockChatSwapping, selectChat } = useChatContext()
    const { files, upload } = useFileContext()
    const acquireUserCredentials = useAcquireUserCredentials()

    const getSocketEndpoint = useCallback(async () => {
        const response = await acquireUserCredentials()
        document.cookie = `auth_token=expired; secure; SameSite=Strict; Max-Age=0` // Invalidate old cookie that had no path so that the more secure cookie is used
        document.cookie = `auth_token=${response.accessToken}; secure; SameSite=Strict; Path=${new URL(socketEndpoint).pathname}`
        return socketEndpoint
    }, [acquireUserCredentials])

    const [outgoingMessage, setOutgoingMessage] = useState(defaultState.outgoingMessage)
    let refreshRequestChatId = defaultState.refreshRequestChatId
    let incomingMessage = defaultState.incomingMessage
    let incomingProgressMessage = defaultState.incomingProgressMessage
    let incomingChunk = defaultState.incomingChunk
    let incomingCellUpdate = defaultState.incomingCellUpdate
    let incomingDocumentUpdate = defaultState.incomingDocumentUpdate
    let incomingSourcesUpdate = defaultState.incomingSourcesUpdate

    const [toastId, setToastId] = useState<Id | null>(null)
    const { sendJsonMessage, lastJsonMessage } = useWebSocket(getSocketEndpoint, {
        onOpen: () => {
            if (toastId) {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Reconnected successfully',
                    autoClose: 5000,
                    closeOnClick: true,
                    closeButton: true,
                })
            }
            setToastId(null)
        },
        shouldReconnect: () => {
            if (!window.navigator.cookieEnabled) {
                captureMessage('Unable to set auth_token cookie')
                toast.error('Cookies are required (functional only). Please enable same-site cookies and refresh the page.')
                return false
            }
            return true
        },
        reconnectAttempts: 10,
        reconnectInterval: attemptNumber => {
            const interval = process.env.NODE_ENV === 'development' ? 1000 : getExponentialBackoff(attemptNumber)
            // attemptNumber is 0 based
            if (attemptNumber > 0) {
                !toastId &&
                    setToastId(
                        toast.warn('Server connection lost, messages can no longer be sent or received. Attempting to automatically reconnect...', {
                            autoClose: false,
                            closeOnClick: false,
                            closeButton: false,
                        })
                    )
                toastId &&
                    toast.warn(`Reconnection failed, trying again in ${interval / 1000} seconds...`, {
                        autoClose: interval,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                    })
            }
            return interval
        },
        onReconnectStop: () => {
            captureMessage('Unable to reconnect websocket')
            toastId &&
                toast.update(toastId, {
                    type: 'error',
                    render: `Unable to reconnect. Messages can no longer be sent or received. ${supportMessage}`,
                    autoClose: false,
                    closeOnClick: false,
                    closeButton: false,
                })
        },
    })

    const [prevChatId, setPrevChatId] = useState(currentChat?.id)
    if (currentChat?.id !== prevChatId) {
        setPrevChatId(currentChat?.id)

        setOutgoingMessage(defaultState.outgoingMessage)
        incomingMessage = defaultState.incomingMessage
        incomingProgressMessage = defaultState.incomingProgressMessage
        incomingChunk = defaultState.incomingChunk
        incomingCellUpdate = defaultState.incomingCellUpdate
        incomingDocumentUpdate = defaultState.incomingDocumentUpdate
        incomingSourcesUpdate = defaultState.incomingSourcesUpdate
    }

    if (isIncomingSocketMessage(lastJsonMessage)) {
        if (lastJsonMessage.type === 'bot' && lastJsonMessage.chatId === currentChat?.id && incomingMessage !== lastJsonMessage) {
            incomingMessage = lastJsonMessage
        } else if (lastJsonMessage.type === 'bot_chunk' && lastJsonMessage.chatId === currentChat?.id && incomingChunk !== lastJsonMessage) {
            incomingChunk = lastJsonMessage
        } else if (
            (lastJsonMessage.type === 'cell_state' || lastJsonMessage.type === 'cell') &&
            lastJsonMessage.chatId === currentChat?.id &&
            incomingCellUpdate !== lastJsonMessage
        ) {
            incomingCellUpdate = lastJsonMessage
        } else if (lastJsonMessage.type === 'document_state' && lastJsonMessage.chatId === currentChat?.id && incomingDocumentUpdate !== lastJsonMessage) {
            incomingDocumentUpdate = lastJsonMessage
        } else if (lastJsonMessage.type === 'sources' && lastJsonMessage.chatId === currentChat?.id && incomingSourcesUpdate !== lastJsonMessage.sources) {
            incomingSourcesUpdate = lastJsonMessage.sources
        } else if (lastJsonMessage.type === 'progress' && incomingProgressMessage !== lastJsonMessage.currentStatus) {
            incomingProgressMessage = lastJsonMessage.currentStatus
        } else if (lastJsonMessage.type === 'notification' && currentChat && currentChat.id === lastJsonMessage.chatId) {
            refreshRequestChatId = lastJsonMessage.chatId
        }
    }

    useEffect(() => {
        if (isIncomingSocketMessage(lastJsonMessage)) {
            switch (lastJsonMessage.type) {
                case 'title':
                    updateChat(lastJsonMessage.chatId, { title: lastJsonMessage.title })
                    break
                case 'state':
                    updateChat(lastJsonMessage.chatId, { status: lastJsonMessage.state })
                    break
                case 'notification':
                    if (currentChat?.id !== lastJsonMessage.chatId) {
                        const { chatId } = lastJsonMessage
                        toast.info(<ChatNotification text={lastJsonMessage.text} onClick={chatId ? () => selectChat(chatId) : undefined} />, {
                            toastId: chatId,
                            autoClose: false,
                        })
                    }
                    break
                case 'error':
                    if (lastJsonMessage.status === 429) {
                        refreshUser()
                    }
                    toast.error(lastJsonMessage.userMessage ?? 'Something went wrong. Please try again and contact support if problems persist.')
                    break
            }
        } else if (lastJsonMessage !== null) {
            captureMessage(`Unexpected socket message received: ${JSON.stringify(lastJsonMessage)}`)
        }
        // We only want to trigger these side effects once when the message is received
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage])

    const handleSendMessage = (chatId: UUID) => (text: string) => {
        // Temporary timestamp will be updated by server
        const payload: MessagePayload = { id: generateId(), chatId, text, type: 'user' }
        setOutgoingMessage({ ...payload, fromUser: true, timestamp: new Date().toISOString(), messageType: 'normal' })
        sendJsonMessage(payload)
        lockCurrentChat()
    }

    const handleRetryLastUserMessage = (chatId: UUID) => () => {
        const payload: MessagePayload = { id: generateId(), chatId, type: 'retry' }
        sendJsonMessage(payload)
        lockCurrentChat()
    }

    const handleSendDocument = (chatId: UUID) => async (text?: string) => {
        if (files) {
            lockCurrentChat()
            blockChatSwapping()

            const id = generateId()
            // Temporary timestamp will be updated by server
            const message: UserMessage = {
                id,
                chatId,
                fromUser: true,
                timestamp: new Date().toISOString(),
                text,
                messageType: 'normal',
                documents: files.map(fileWithId => ({
                    id: fileWithId.id,
                    name: fileWithId.file.name,
                    state: 'uploading',
                })),
            }
            setOutgoingMessage(message)

            const { uploadedIds } = await upload(true)

            incomingMessage = {
                ...message,
                documents: message.documents.map(document => ({ ...document, state: uploadedIds.includes(document.id) ? 'waiting' : 'error' })),
            }

            if (uploadedIds.length > 0) {
                const payload: MessagePayload = {
                    id,
                    chatId,
                    text,
                    documentIds: uploadedIds,
                    type: 'user',
                }
                sendJsonMessage(payload)
            } else {
                unlockCurrentChat()
            }

            unblockChatSwapping()
        } else {
            toast.warn('Please attach a file to upload')
        }
    }

    return (
        <MessageContext.Provider
            value={{
                refreshRequestChatId,
                incomingMessage,
                incomingProgressMessage,
                incomingDocumentUpdate,
                incomingChunk,
                incomingCellUpdate,
                incomingSourcesUpdate,
                outgoingMessage,
                sendMessage: handleSendMessage,
                sendDocument: handleSendDocument,
                retryLastUserMessage: handleRetryLastUserMessage,
            }}
        >
            {children}
        </MessageContext.Provider>
    )
}

const useMessageContext = () => useContext(MessageContext)

export { WithMessageContext, useMessageContext }
