import { styled } from '@mui/material'

const CenteredContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
})

export default CenteredContainer
