import MiniIconButton from '../common/MiniIconButton'
import ChevronIcon from '../../icons/ChevronIcon'
import StarIcon from '../../icons/StarIcon'
import { PromptDetail, PromptSummary } from '../prompt-library/types'
import { usePromptContext } from '../../context/PromptContext'
import { styled, Typography, useTheme } from '@mui/material'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

const IconContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '30px',
})

interface ActiveButtonProps {
    $isActive: boolean
}

const StyledMiniStarIconButton = styled(MiniIconButton, {
    shouldForwardProp: forwardPropsWithTransient,
})<ActiveButtonProps>(({ theme, $isActive }) => ({
    backgroundColor: $isActive ? theme.palette.primary.main : theme.palette.secondary.dark,
}))

const StyledMiniChevronButton = styled(MiniIconButton, {
    shouldForwardProp: forwardPropsWithTransient,
})<ActiveButtonProps>(({ theme, $isActive }) => ({
    backgroundColor: $isActive ? theme.palette.primary.main : theme.palette.secondary.dark,
    '& svg': {
        height: '16px',
        width: '16px',
    },
}))

const VoteContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
})

const VoteCount = styled(Typography)({
    fontWeight: 900,
    margin: '0px 5px',
    minWidth: '20px',
    textAlign: 'center',
})

interface PromptMetaButtonsProps {
    className?: string
    prompt: PromptDetail | PromptSummary
}

const PromptMetaButtons = ({ className, prompt }: PromptMetaButtonsProps) => {
    const { toggleFavourite, vote } = usePromptContext()

    const { palette } = useTheme()

    return (
        <IconContainer className={className}>
            <InfoTooltip title='Favourite prompt'>
                <StyledMiniStarIconButton
                    onClick={event => {
                        event.stopPropagation()
                        toggleFavourite(prompt.id, prompt.isFavourite)
                    }}
                    $isActive={prompt.isFavourite}
                >
                    <StarIcon color={palette.primary.contrastText} fill={prompt.isFavourite ? `${palette.primary.contrastText}` : 'none'} />
                </StyledMiniStarIconButton>
            </InfoTooltip>
            <VoteContainer>
                <InfoTooltip title='Downvote prompt'>
                    <StyledMiniChevronButton
                        onClick={event => {
                            event.stopPropagation()
                            vote(-1, prompt.id, prompt.myVote)
                        }}
                        $isActive={prompt.myVote === -1}
                    >
                        <ChevronIcon direction='down' color={palette.primary.contrastText} />
                    </StyledMiniChevronButton>
                </InfoTooltip>
                <VoteCount variant='body2'>{prompt.totalVotes}</VoteCount>
                <InfoTooltip title='Upvote prompt'>
                    <StyledMiniChevronButton
                        onClick={event => {
                            event.stopPropagation()
                            vote(1, prompt.id, prompt.myVote)
                        }}
                        $isActive={prompt.myVote === 1}
                    >
                        <ChevronIcon direction='up' color={palette.primary.contrastText} />
                    </StyledMiniChevronButton>
                </InfoTooltip>
            </VoteContainer>
        </IconContainer>
    )
}

export default PromptMetaButtons
