import { default as FormType } from '@rjsf/core'
import { Form } from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import { forwardRef, useRef } from 'react'
import styled from 'styled-components'
import BotConfigButton from '../bot-config/BotConfigButton'
import Loading from '../common/Loading'
import TransparentButton from '../common/TransparentButton'

const StyledForm = styled(Form)({
    flex: 1,
    overflowY: 'auto',
    marginBottom: '15px',
    paddingRight: '15px',
})

const StyledTransparentButton = styled(TransparentButton)({
    marginLeft: '10px',
    textAlign: 'center',
})

const Controls = styled('div')({
    display: 'flex',
})

// Minimal validation function that enables support of conditional enum schemas and ignores all other validation requirements.
export const isValidEnumOnly = (schema: RJSFSchema, formData: unknown, rootSchema: RJSFSchema) => {
    if (schema.type === 'object') {
        for (const key in schema.properties) {
            const property = schema.properties[key]
            if (typeof property !== 'object' || !property) {
                continue
            }

            // resolve any ref in the property declaration
            // The ref is of the format #/<defs_key>/<property_key>
            // data from rootSchema.<defs_key>.<property_key> needs to be inserted here
            const ref = property['$ref']
            let full_property = { ...property }
            if (ref) {
                const refPath = ref.substring(2).split('/') // Remove '#/' and split by '/'

                const defs = rootSchema[refPath[0]]
                const def = defs[refPath[1]] || {}

                full_property = { ...property, ...def }
            }

            // validate enum values
            const data = typeof formData === 'object' && formData !== null ? (formData as Record<string, unknown>)[key] : undefined // Technically object could have non-string keys but they are always coerced into strings

            if (full_property.type === 'string' && full_property.enum) {
                const isDataValidType = typeof data === 'string' || typeof data === 'number'
                if (!isDataValidType || !full_property.enum.includes(data)) {
                    return false
                }
            }

            // recursively check child objects
            if (typeof full_property === 'object' && full_property.type === 'object') {
                if (!isValidEnumOnly(full_property, data, rootSchema)) {
                    return false
                }
            }
        }
    }
    return true
}

interface FormWrapperProps {
    className?: string
    formData?: { schema: Record<string, unknown>; uiSchema?: Record<string, unknown>; values: Record<string, unknown> } | null
    disabled: boolean
    onSubmit: (update: Record<string, unknown>) => void
    onCancel?: () => void
    onChange?: (update: Record<string, unknown>) => void
}

const FormWrapper = forwardRef<FormType, FormWrapperProps>(({ className, formData, disabled, onSubmit, onCancel, onChange }, passedRef) => {
    const defaultRef = useRef<FormType>(null)
    if (!formData) {
        return <Loading primaryColor fullSize />
    }

    return (
        <>
            <StyledForm
                className={className}
                ref={passedRef ?? defaultRef}
                schema={formData.schema}
                uiSchema={{
                    ...formData.uiSchema,
                    'ui:submitButtonOptions': {
                        norender: true,
                    },
                }}
                formData={formData.values}
                onChange={({ formData }) => onChange && onChange(formData)}
                // Disable validator to avoid CSP issue as they generate JS at runtime.
                // If we need more complex validation than HTML5 we will need to setup precompiled validators
                validator={{
                    validateFormData: () => ({ errors: [], errorSchema: {} }),
                    toErrorList: () => [],
                    isValid: isValidEnumOnly,
                    rawValidation: () => ({}),
                }}
                onSubmit={({ formData }) => onSubmit(formData)}
                disabled={disabled}
                showErrorList={false}
            />
            <Controls>
                {onCancel && (
                    <StyledTransparentButton onClick={onCancel} aria-label='cancel form' type='reset'>
                        Go Back
                    </StyledTransparentButton>
                )}
                {!passedRef && (
                    <BotConfigButton
                        onClick={() => defaultRef.current?.submit()}
                        disabled={disabled}
                        type='submit'
                        title={disabled ? 'Form Disabled' : 'Submit'}
                        aria-label='submit form'
                    >
                        Save
                    </BotConfigButton>
                )}
            </Controls>
        </>
    )
})

export default FormWrapper
