import Loading from '../common/Loading'
import Breadcrumb from '../common/Breadcrumb'
import { usePromptContext } from '../../context/PromptContext'
import { useGet } from '../../hooks/useGet'
import { libraryPromptsEndpoint } from '../../endpoints'
import FormWrapper from '../forms/FormWrapper'
import { PromptCreationResponse, PromptLibrary } from './types'
import { JSONFormPayload, JSONFormResponse } from '../../apiTypes'
import { useState } from 'react'
import { styled, Typography } from '@mui/material'

const Container = styled('div')({
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '60%',
    maxHeight: '90%',
})

const StyledFormWrapper = styled(FormWrapper)({
    height: '100%',
    width: '100%',
})

interface PromptFormProps {
    onSubmit: (payload: JSONFormPayload) => void
    onBack: () => void
    initialFormValue: JSONFormPayload | null
    library: PromptLibrary
}

const PromptForm = ({ onSubmit, onBack, initialFormValue, library }: PromptFormProps) => {
    const { onSelectPrompt } = usePromptContext()
    const [formValues, setFormValues] = useState<JSONFormPayload | null>(initialFormValue)

    const [emptyPrompt, loadingEmptyPrompt] = useGet<PromptCreationResponse>(libraryPromptsEndpoint(library.id))

    if (loadingEmptyPrompt || !emptyPrompt) {
        return (
            <>
                <Breadcrumb onClick={() => onSelectPrompt(null)} label='Back to Library' />
                <Loading fullSize={true} primaryColor={true} />
            </>
        )
    }

    if (formValues === null) {
        setFormValues({
            library: library.id,
            ...emptyPrompt.prompt.values,
        })
    }

    const formData: JSONFormResponse = {
        // title/desc aren't needed in this form so we strip them. Title is in modal header, and description is not needed
        schema: { ...emptyPrompt.prompt.schema, description: '', title: '' },
        uiSchema: emptyPrompt.prompt.uiSchema,
        values: formValues ?? {},
    }
    // Note we also expereince this warn in the console due to some issue with allOf values:
    // https://github.com/rjsf-team/react-jsonschema-form/issues/2927#issuecomment-1400329503

    return (
        <>
            <Breadcrumb onClick={onBack} label='Back to Library' />
            <Container>
                <Typography variant='h3'>{initialFormValue ? `Editing prompt for: ${library.title}` : `Create a new prompt for: ${library.title}`}</Typography>
                <StyledFormWrapper formData={formData} disabled={false} onSubmit={onSubmit} onChange={setFormValues} />
            </Container>
        </>
    )
}

export default PromptForm
