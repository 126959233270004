import { styled } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '90px',
    margin: '0',

    [theme.breakpoints.up('sm')]: {
        margin: '12px',
    },
}))

const WarningText = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    margin: 'auto',
}))

interface WarningAreaProps {
    message: string
}

const WarningArea = ({ message }: WarningAreaProps) => (
    <Container>
        <WarningText>{message}</WarningText>
    </Container>
)

export default WarningArea
