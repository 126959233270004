import { useState } from 'react'
import { useAuthHeaders } from './useAuthHeaders'
import { UUID } from '../types'
import { captureException, captureMessage } from '@sentry/react'
import { setSentryTransactionForRequest } from '../utils/logging'

export const useDelete = (url: string | ((id: UUID) => string) | null): [makeRequest: (id: UUID) => Promise<boolean>, loading: boolean] => {
    const [loading, setLoading] = useState(false)
    const generateHeaders = useAuthHeaders()

    const makeRequest = async (id: UUID) => {
        if (url === null) {
            return false
        }
        const requestUrl = typeof url === 'function' ? url(id) : `${url}/${id}`

        setSentryTransactionForRequest(requestUrl)
        setLoading(true)

        try {
            const response = await fetch(requestUrl, {
                method: 'DELETE',
                headers: await generateHeaders(),
            })

            setLoading(false)
            if (response.ok) {
                return true
            }
            captureMessage(`${response.status}: ${response.statusText}`)
            const error = await response.text()
            captureMessage(error)

            return false
        } catch (error) {
            captureException(error)
        }

        setLoading(false)
        return false
    }

    return [makeRequest, loading]
}
