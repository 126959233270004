import { styled } from '@mui/material'
import { zIndexes } from './styles'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

interface StyledButtonProps {
    $isOpen: boolean
}

const StyledButton = styled('button', {
    shouldForwardProp: forwardPropsWithTransient,
})<StyledButtonProps>(({ $isOpen, theme }) => ({
    position: 'absolute',
    top: '36px',
    right: '36px',
    zIndex: `${zIndexes.mobileNavigationButton}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    width: '2em',
    height: '1.6em',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',

    span: {
        height: '0.3em',
        backgroundColor: theme.palette.text.primary,
        borderRadius: '20px',
        transition: 'all 0.3s ease',
        position: 'relative',
        transformOrigin: '6px',

        '&:first-child': {
            transform: $isOpen ? 'rotate(45deg)' : 'rotate(0)',
            width: $isOpen ? '2.1em' : '2.5em',
        },

        '&:nth-child(2)': {
            transform: $isOpen ? 'rotate(-45deg)' : 'rotate(0)',
            width: $isOpen ? '2.1em' : '1.6em',
        },
    },
}))

interface BurgerButtonProps {
    isOpen: boolean
    onClick: () => void
}

const BurgerButton = ({ isOpen, onClick }: BurgerButtonProps) => (
    <StyledButton $isOpen={isOpen} onClick={onClick} aria-label='toggle menu'>
        <span />
        <span />
    </StyledButton>
)

export default BurgerButton
