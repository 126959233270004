import { useCallback, useState } from 'react'
import { useAuthHeaders } from './useAuthHeaders'
import { captureException, captureMessage } from '@sentry/react'
import { toast } from 'react-toastify'
import { genericErrorMsg } from '../utils/userMessages'
import { setSentryTransactionForRequest } from '../utils/logging'

interface Options {
    suppressDefaultErrorBehaviour: boolean
    method: 'POST' | 'PATCH' | 'PUT'
}

const defaultOptions: Options = {
    suppressDefaultErrorBehaviour: false,
    method: 'POST',
}

// Might be a better way of doing this but because we create an intersect type with 3 generics we need to provide 3 here
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UsePostWithSetUrl = <TPayload, TResponse, TUrl = undefined>(
    url: string | null,
    options?: Partial<Options>
) => [makeRequest: (payload: TPayload) => Promise<TResponse | null>, loading: boolean, error: string | null]

type UsePostWithDynamicUrl = <TPayload, TResponse, TUrl>(
    url: ((urlValue: TUrl) => string) | null,
    options?: Partial<Options>
) => [makeRequest: (payload: TPayload, urlValue: TUrl) => Promise<TResponse | null>, loading: boolean, error: string | null]

// Generics (as usual) causing problems in TS here so we have to explicitly state the type of parameters
export const usePost: UsePostWithSetUrl & UsePostWithDynamicUrl = <TPayload, TResponse, TUrl>(
    url: string | null | ((value: TUrl) => string),
    options?: Partial<Options>
) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const generateHeaders = useAuthHeaders()

    const { suppressDefaultErrorBehaviour, method } = { ...defaultOptions, ...options }

    const makeRequest = useCallback(
        async (payload: TPayload, urlValue?: TUrl) => {
            const requestUrl = typeof url === 'function' ? (typeof urlValue === 'undefined' ? null : url(urlValue)) : url
            if (!requestUrl) {
                return null
            }

            setSentryTransactionForRequest(requestUrl)
            setLoading(true)
            setError(null)

            try {
                const headers = await generateHeaders()
                let body: BodyInit

                if (payload instanceof FormData) {
                    body = payload
                } else {
                    headers.append('Content-Type', 'application/json')
                    body = JSON.stringify(payload)
                }

                const response = await fetch(requestUrl, {
                    method,
                    headers,
                    body,
                })

                if (response.ok) {
                    try {
                        const json = await response.json()
                        if (json === null) return true as TResponse
                        return json as TResponse
                    } catch {
                        return true as TResponse
                    } finally {
                        setLoading(false)
                    }
                } else {
                    const status = `${response.status}: ${response.statusText}`

                    if (response.status.toString().startsWith('5')) {
                        throw new Error(status)
                    }

                    try {
                        // There seems to be a bug in Jest that allows cloning after reading as json, ensure changes are tested outside of Jest
                        const json = await response.clone().json()

                        if (json.detail) {
                            setError(json.detail)
                            toast.error(json.detail)
                        } else {
                            throw Error('Detail property not found in response')
                        }
                    } catch {
                        captureMessage(status)
                        const error = await response.text()
                        error && captureMessage(error)

                        if (!suppressDefaultErrorBehaviour) {
                            toast.error(genericErrorMsg)
                        }
                    }
                }
            } catch (error) {
                captureException(error)

                if (!suppressDefaultErrorBehaviour) {
                    toast.error(genericErrorMsg)
                }
            }

            setLoading(false)
            return null
        },
        [generateHeaders, url, suppressDefaultErrorBehaviour, method]
    )

    return [makeRequest, loading, error]
}
