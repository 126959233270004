import { Bot } from '../../types'
import Modal, { ModalProps } from '../common/Modal'
import { styled, Typography } from '@mui/material'
import KnowledgeBaseDisplay from '../bot-config/knowledge-base/KnowledgeBaseDisplay'
import Details from './Details'

const StyledModal = styled(Modal)(({ theme }) => ({
    minHeight: '30vh',
    maxHeight: '80vh',
    paddingRight: '16px',
    paddingLeft: '32px',
}))

const ModalContent = styled('div')({
    overflowY: 'auto',
    paddingRight: '16px',
})

const Item = styled('div')({
    margin: '16px 0px',
})

const Label = styled(Typography)({
    padding: '4px 0',
})

interface BotDetailsModalProps extends ModalProps {
    bot: Bot
}

const BotDetailsModal = ({ show, onClose, bot }: BotDetailsModalProps) => {
    const supportsKnowledgeBase = bot.toolType === 'chatbot' && bot.supportsKnowledgeBase

    return (
        <StyledModal show={show} onClose={onClose} heading={`Details for ${bot.name}`}>
            <ModalContent>
                <Details id={bot.id} name={bot.name} description={bot.description} engines={bot.aiEngines} />
                {supportsKnowledgeBase && (
                    <Item>
                        <Label variant='h6'>Knowledge Base</Label>
                        <KnowledgeBaseDisplay botId={bot.id} readonly />
                    </Item>
                )}
            </ModalContent>
        </StyledModal>
    )
}

export default BotDetailsModal
