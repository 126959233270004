import { useEffect } from 'react'
import { documentDownloadUrlEndpoint } from '../endpoints'
import { DocumentState, UUID, isDownloadableState } from '../types'
import { useGet } from './useGet'
import useLoadingDebounce from './useLoadingDebounce'

export const useDocumentDownload = (documentId?: UUID, documentState?: DocumentState): [boolean, () => void, boolean] => {
    const [documentUrl, loading, getDocumentUrl] = useGet<string>(documentId ? documentDownloadUrlEndpoint(documentId) : null, {
        requestOnRender: false,
    })
    const debouncedLoading = useLoadingDebounce(loading, 500)

    useEffect(() => {
        documentUrl && window.open(documentUrl, '_blank')
    }, [documentUrl])

    return [isDownloadableState(documentState), getDocumentUrl, debouncedLoading]
}
