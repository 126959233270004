import { styled } from '@mui/material'
import { AiEngine, UUID } from '../../types'
import { Typography } from '@mui/material'
import BotOverviewInfo from '../BotOverviewInfo'

const Item = styled('div')({
    margin: '16px 0px',
})

const Label = styled(Typography)({
    padding: '4px 0',
})

interface DetailsProps {
    id: UUID
    name: string
    description: string
    engines: AiEngine[]
}

const Details = ({ name, description, engines }: DetailsProps) => {
    return (
        <div>
            <Item>
                <Label variant='h6'>Name</Label>
                <Typography>{name}</Typography>
            </Item>
            <Item>
                <Label variant='h6'>Description</Label>
                <Typography>{description}</Typography>
            </Item>
            <Item>
                <BotOverviewInfo aiEngines={engines} />
            </Item>
        </div>
    )
}

export default Details
