import { environmentVariables } from './env'
import { UUID } from './types'

const { apiUri } = environmentVariables

const apiUrl = new URL(apiUri)

export const socketEndpoint = `${apiUrl.href.replace(apiUrl.protocol, apiUrl.protocol.replace('http', 'ws'))}/sockets/connect`

export const currentUserEndpoint = `${apiUri}/user/me`

// Bots

export const botListEndpoint = `${apiUri}/bot`

export const newBotOptionsEndpoint = `${apiUri}/bot/new_bot_options`

export const botCloneEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/clone`

export const botInfoEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/info`

export const botConfigEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/config`

export const botBalancersConfigEndpoint = (botId: UUID) => `${botConfigEndpoint(botId)}/balancers`

export const botFieldsEndpoint = (botId: UUID) => `${botConfigEndpoint(botId)}/fields`

export const botFieldSchemaEndpoint = (botId: UUID) => `${botFieldsEndpoint(botId)}/schema`

export const botFieldCreateEndpoint = (botId: UUID) => `${botFieldsEndpoint(botId)}/new`

export const botFieldEndpoint = (botId: UUID) => (fieldId: UUID) => `${botFieldsEndpoint(botId)}/${fieldId}`

// Templates

export const templatesEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/templates`

export const templateUrlEndpoint = (botId: UUID, templateId: UUID) => `${templatesEndpoint(botId)}/${templateId}/get_download_url`

export const templateUploadUrlsEndpoint = (botId: UUID) => `${templatesEndpoint(botId)}/get_upload_urls`

export const templateFinaliseEndpoint = (botId: UUID) => (templateId: UUID) => `${templatesEndpoint(botId)}/${templateId}/finalise`

// Knowledge base

export const knowledgeBaseEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/knowledge_base`

const knowledgeBaseDocumentEndpoint = (botId: UUID, documentId: UUID) => `${knowledgeBaseEndpoint(botId)}/${documentId}`

export const knowledgeBaseDocumentUploadUrlsEndpoint = (botId: UUID) => `${knowledgeBaseEndpoint(botId)}/get_upload_urls`

export const knowledgeBaseDocumentFinaliseEndpoint = (botId: UUID) => (documentId: UUID) => `${knowledgeBaseDocumentEndpoint(botId, documentId)}/finalise`

export const processKnowledgeBaseEndpoint = (botId: UUID) => `${knowledgeBaseEndpoint(botId)}/process_batch`

// Chats

export const chatEndpoint = `${apiUri}/chat`

export const sendMessageEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/send`

export const getMessagesEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/messages`

export const updateChatTitleEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/title`

export const downloadChatEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/download`

export const getSourcesEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/sources`

// Docs

const documentsEndpoint = `${apiUri}/documents`

const documentEndpoint = (documentId: UUID) => `${documentsEndpoint}/${documentId}`

export const documentDownloadUrlEndpoint = (documentId: UUID) => `${documentEndpoint(documentId)}/get_download_url`

// Session Docs

const sessionDocumentsEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/documents`

const sessionDocumentEndpoint = (chatId: UUID, documentId: UUID) => `${sessionDocumentsEndpoint(chatId)}/${documentId}`

export const sessionDocumentUploadUrlsEndpoint = (chatId: UUID) => `${sessionDocumentsEndpoint(chatId)}/get_upload_urls`

export const sessionDocumentFinaliseEndpoint = (chatId: UUID) => (documentId: UUID) => `${sessionDocumentEndpoint(chatId, documentId)}/finalise`

// Reports

const reportEndpoint = (chatId: UUID) => `${chatEndpoint}/${chatId}/tool`

export const genericReportsEndpoint = (chatId: UUID) => `${reportEndpoint(chatId)}/generic_reports`

export const downloadReportsEndpoint = (chatId: UUID) => `${genericReportsEndpoint(chatId)}/download`

// Prompts

export const promptsEndpoint = `${apiUri}/prompt`

export const promptsMetadataEndpoint = `${promptsEndpoint}/metadata`

export const librariesEndpoint = `${apiUri}/library`

export const libraryImportEndpoint = `${librariesEndpoint}/import`

export const libraryEndpoint = (libraryId: UUID) => `${librariesEndpoint}/${libraryId}`

export const libraryExportEndpoint = (libraryId: UUID) => `${libraryEndpoint(libraryId)}/export`

export const libraryPromptsEndpoint = (libraryId: UUID) => `${librariesEndpoint}/${libraryId}/prompt`

export const promptCreationEndpoint = libraryPromptsEndpoint

export const promptDetailEndpoint = (libraryId: UUID, promptId: UUID) => `${libraryPromptsEndpoint(libraryId)}/${promptId}`

export const promptDeletionEndpoint = (libraryId: UUID) => (promptId: UUID) => `${libraryPromptsEndpoint(libraryId)}/${promptId}`

export const promptVotingEndpoint = (libraryId: UUID) => (promptId: UUID) => `${promptDetailEndpoint(libraryId, promptId)}/vote`

export const promptFavouritingEndpoint = (libraryId: UUID) => (promptId: UUID) => `${promptDetailEndpoint(libraryId, promptId)}/favourite`

export const promptPreviewTemplateEndpoint = (libraryId: UUID, promptId: UUID) => `${promptDetailEndpoint(libraryId, promptId)}/preview`

export const promptRenderTemplateEndpoint = (libraryId: UUID, promptId: UUID) => `${promptDetailEndpoint(libraryId, promptId)}/render`

export const promptSharingEndpoint = (libraryId: UUID) => `${libraryEndpoint(libraryId)}/share`

export const promptUnsharingEndpoint = (libraryId: UUID) => (userId: UUID) => `${libraryEndpoint(libraryId)}/share/${userId}`

export const promptPublishingEndpoint = (libraryId: UUID) => `${libraryEndpoint(libraryId)}/publish`

export const promptPermissionsEndpoint = (libraryId: UUID) => `${libraryEndpoint(libraryId)}/permissions`

const adminEndpoint = `${apiUri}/admin`

export const adminUsersEndpoint = `${adminEndpoint}/users`

export const accountSettingsEndpoint = `${adminEndpoint}/settings/organisation`

export const adminAnalyticsEndpoint = (reportType: string, startDate: string, endDate: string) =>
    `${adminEndpoint}/analytics?${new URLSearchParams({ report_type: reportType, start_date: startDate, end_date: endDate })}`

const adminUserEndpoint = `${adminEndpoint}/user`
export const changeMemberActiveEndpoint = (isActive: boolean, userId: UUID) => `${adminUserEndpoint}/${userId}/${isActive ? 'deactivate' : 'activate'}`

export const userRolesEndpoint = (userId: UUID) => `${adminUserEndpoint}/${userId}/role`

export const userQuotaEndpoint = (userId: UUID) => `${adminUserEndpoint}/${userId}/quota`

export const botUsersEndpoint = (botId: UUID) => `${botListEndpoint}/${botId}/bot_users`

export const removeBotUserEndpoint = (botId: UUID) => (userId: UUID) => `${botUsersEndpoint(botId)}/${userId}`

export const botTenantsEndpoint = (botId: UUID) => `${botListEndpoint}/bot_tenants/${botId}`

// Skimmer

export const documentSkimmerEndpoint = (chatId: UUID) => `${reportEndpoint(chatId)}/document_skimmer`

export const skimmerDocumentsEndpoint = (chatId: UUID) => `${documentSkimmerEndpoint(chatId)}/documents`

export const skimmerPromptsEndpoint = (chatId: UUID) => `${documentSkimmerEndpoint(chatId)}/prompts`

export const skimmerExportEndpoint = (chatId: UUID) => `${documentSkimmerEndpoint(chatId)}/get_export_url`

export const skimmerExcelImportEndpoint = (chatId: UUID) => `${skimmerDocumentsEndpoint(chatId)}/excel_import`

export const runDocumentSkimmerEndpoint = (chatId: UUID) => `${documentSkimmerEndpoint(chatId)}/run`

// Issues

export const knownIssuesEndpoint = `${apiUri}/known_issues`

export const issueReportEndpoint = `${apiUri}/issue_report`
