import { Document, isLoadingState } from '../types'
import Chip from './common/Chip'
import { useDocumentDownload } from '../hooks/useDocumentDownload'
import { styled } from '@mui/material'
import { forwardPropsWithTransient } from '../utils/muiUtils'

interface StyledChipProps {
    $error: boolean
}

const StyledChip = styled(Chip, {
    shouldForwardProp: forwardPropsWithTransient,
})<StyledChipProps>(({ $error, theme }) => ({
    '&&&': {
        minWidth: 'unset',
        maxWidth: '100%',
    },

    '& ~ *': {
        marginTop: '10px',
    },

    ...($error && {
        textDecoration: 'line-through',
    }),
}))

interface DocumentChipProps {
    className?: string
    document: Document
}

const DocumentChip = ({ className, document }: DocumentChipProps) => {
    const { id, state, name } = document

    const [isDownloadable, download, debouncedLoading] = useDocumentDownload(id, state)

    return (
        <StyledChip
            className={className}
            key={id}
            label={name}
            title={isDownloadable ? `Click to download ${name}` : name}
            onClick={isDownloadable ? download : undefined}
            loading={isLoadingState(state) || debouncedLoading}
            $error={state === 'error'}
        />
    )
}

export default DocumentChip
