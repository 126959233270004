import { Atomic } from '../../../types'

export const createMessageWithReferences = (message: string, references: Atomic[]) => {
    let currentIndex = 0

    const sortedReferences = references.sort((a, b) => a.startIndex - b.startIndex)

    let output = sortedReferences.reduce((acc, ref) => {
        // Add text before reference
        if (ref.startIndex > currentIndex) {
            acc += message.substring(currentIndex, ref.startIndex)
        }

        // Add the reference span
        acc += `<span id="${ref.id}" class="reference-${ref.evidenceClass}">`
        acc += message.substring(ref.startIndex, ref.endIndex)
        acc += '</span>'

        currentIndex = ref.endIndex
        return acc
    }, '')

    // Add remaining text
    if (currentIndex < message.length) {
        output += message.substring(currentIndex)
    }

    return output
}
