import { Link, ListItem, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { knowledgeBaseEndpoint } from '../../../endpoints'
import { useDelete } from '../../../hooks/useDelete'
import CrossIcon from '../../../icons/CrossIcon'
import FileIcon from '../../../icons/FileIcon'
import { isLoadingState, KBDocument, UUID } from '../../../types'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'
import Loading from '../../common/Loading'
import DeleteTooltip from '../../common/tooltips/DeleteTooltip'
import TransparentButton from '../../common/TransparentButton'
import { useDocumentDownload } from '../../../hooks/useDocumentDownload'

const FlexBox = styled('span')({
    display: 'flex',
})

const DocListItem = styled(ListItem)({
    padding: '4px 0 4px 0',
    justifyContent: 'space-between',
})

const DocumentTitle = styled(Typography, { shouldForwardProp: forwardPropsWithTransient })<{ $toDelete: boolean }>(({ $toDelete }) => ({
    marginLeft: 8,
    textDecoration: $toDelete ? 'line-through' : 'initial',
}))

const DeleteButton = styled(TransparentButton)(() => ({
    marginLeft: '8px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '5px',
}))

const SizedLoading = styled(Loading)({
    width: '12px',
    height: '12px',
})

interface KnowledgeBaseListItemProps {
    sourceDoc: KBDocument
    botId: UUID
    onDeleteSuccess: () => void
    loading: boolean
    readonly?: boolean
}

const KnowledgeBaseListItem = ({ sourceDoc, botId, onDeleteSuccess, loading, readonly = true }: KnowledgeBaseListItemProps) => {
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
    const [isDownloadable, download, debouncedLoading] = useDocumentDownload(sourceDoc.id, sourceDoc.state)

    const [deleteKBDocument, deleting] = useDelete(knowledgeBaseEndpoint(botId))

    const handleDelete = async () => {
        const success = await deleteKBDocument(sourceDoc.id)
        success && onDeleteSuccess()
    }

    const processing = loading || isLoadingState(sourceDoc.state) || (sourceDoc.state === 'extracted' && !sourceDoc.finishedEmbedding)

    return (
        <DeleteTooltip
            id={sourceDoc.id}
            targetName={sourceDoc.name}
            onDelete={handleDelete}
            open={deleteTooltipOpen}
            handleClose={() => setDeleteTooltipOpen(false)}
        >
            <DocListItem key={sourceDoc.id} className='visible-on-hover-parent'>
                <FlexBox>
                    <FileIcon width={16} />

                    <DocumentTitle $toDelete={deleting} color={sourceDoc.state === 'error' ? 'error' : 'textPrimary'}>
                        {isDownloadable ? (
                            <Link onClick={download} title={`download: ${sourceDoc.name}`} color='inherit'>
                                {sourceDoc.name}
                                {debouncedLoading ? <SizedLoading primaryColor /> : null}
                            </Link>
                        ) : (
                            <Typography> {sourceDoc.name}</Typography>
                        )}
                    </DocumentTitle>
                    {!readonly && (
                        <DeleteButton
                            className='visible-on-hover'
                            type='button'
                            aria-label='remove document'
                            title='Delete from knowledge base'
                            disabled={isLoadingState(sourceDoc.state)}
                            onClick={() => setDeleteTooltipOpen(true)}
                        >
                            <CrossIcon width={10} height={10} />
                        </DeleteButton>
                    )}
                </FlexBox>
                {processing && (
                    <FlexBox sx={{ gap: 2 }}>
                        <SizedLoading primaryColor />
                        <Typography>Processing...</Typography>
                    </FlexBox>
                )}
            </DocListItem>
        </DeleteTooltip>
    )
}

export default KnowledgeBaseListItem
