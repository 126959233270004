import { SharingRole, isOwnerOrHigher, isEditorOrHigher } from '../../types'

export const canExportLibrary = (isMaintainer: boolean, role: SharingRole) => isMaintainer && isOwnerOrHigher(role)

export const canShareLibrary = (isMaintainer: boolean, role: SharingRole) => isMaintainer && isEditorOrHigher(role)

export const canDeleteLibrary = isOwnerOrHigher

export const canEditLibrary = isEditorOrHigher

export const canEditPrompt = isEditorOrHigher
