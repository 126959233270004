import { styled } from '@mui/material'
import UnstyledButton from './UnstyledButton'
import { highlightOnHover } from './styles'

const Button = styled(UnstyledButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '10px 16px',
    borderRadius: '8px',
    transition: 'all 150ms ease-out',
    '&:disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
        cursor: 'unset',
    },
    ...highlightOnHover(theme.palette.primary.main),
}))

export default Button
