import ReactMarkdown, { Options } from 'react-markdown'
import { styled } from '@mui/material'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { MouseEventHandler } from 'react'
import { Atomic } from '../../../types'
import ComponentWithReferences from './ComponentWithReferences'

const StyledMarkdownMessage = styled(ReactMarkdown)(({ theme }) => ({
    [`& > :first-child`]: {
        marginTop: 0,
    },
    [`& > :last-child`]: {
        marginBottom: 0,
    },
    [`& pre`]: {
        overflowX: 'auto',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: '12px',
        padding: '12px',
        border: 'none',
    },
    [`& p`]: {
        whiteSpace: 'pre-wrap',
    },
    [`& a`]: {
        color: 'inherit',
    },
    [`& th`]: {
        overflow: 'hidden',
    },
    [`& td`]: {
        verticalAlign: 'baseline',
    },
    [`& table, & th, & td`]: {
        border: '1px solid',
        borderCollapse: 'collapse',
    },
    [`& th, & td`]: {
        padding: '2px 5px',
        textAlign: 'left',
    },
}))

type TypographySubset = Extract<'chatMessage' | 'body1', keyof TypographyOptions>

interface MarkDownMessageProps extends Options {
    variant?: TypographySubset
    className?: string
    handleReferenceClick?: MouseEventHandler
    selectedSpanId?: string
    references?: Atomic[]
}

const MarkdownMessage = ({ variant = 'chatMessage', className, handleReferenceClick, references, selectedSpanId, children, ...rest }: MarkDownMessageProps) => {
    return (
        <StyledMarkdownMessage
            className={`${variant}-markdown ${className}`}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
                // When react-markdown creates HTML components, we process the content to include reference spans
                span: ({ node, children }) => (
                    <ComponentWithReferences node={node} childComponents={children} selectedSpanId={selectedSpanId} handleClick={handleReferenceClick} />
                ),
            }}
            {...rest}
        >
            {children}
        </StyledMarkdownMessage>
    )
}

export default MarkdownMessage
